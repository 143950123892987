import { DefaultResponse, getApiBaseUrl } from 'api';
import customAxios from 'api/customAxios';
import { Access } from 'types/types';

type Response = {
  deviceWarning: {
    controller: {
      farm_number: number;
      controller_number: number;
      datetime: string; //'2024-01-25 15:34:00';
    }[];
    cell: {
      cell_number: number;
      farm_number: number;
      line_number: number;
      block_number: number;
      floor_number: number;
      datetime: string; //'2023-12-22 11:19:27';
    }[];
  };
} & DefaultResponse;

export const requestDeviceWarning = async ({
  payload,
  access,
}: {
  payload: { farmNumber: number };
  access: Access;
}) => {
  const requestBody = new FormData();
  requestBody.append('farmNumber', payload.farmNumber.toString());
  try {
    const response = await customAxios.post<Response>(
      `${getApiBaseUrl(access)}/web/deviceWarning.php`,
      requestBody,
    );
    return response.data;
  } catch (error) {
    console.error('Request Get Device Warning error', error);
    throw error;
  }
};

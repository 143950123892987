import useLocaleStore from 'store/localeStore';
import { getLocaleDateString } from 'utils/date/date';
import { getLocaleTimeString } from 'utils/date/time';
import useTranslate from 'hooks/useTranslate';
import { useDeviceWarningQuery } from 'hooks/reactQuery/alarm/deviceWarningQuery';
import clsx from 'clsx';
import { CellWarning } from 'types/alarm';

export default function AlarmList() {
  const t = useTranslate();
  const tGlobal = useTranslate('global');
  const { locale } = useLocaleStore();
  const { data } = useDeviceWarningQuery();
  if (!data) return null;
  const { cell: cells, controller } = data.deviceWarning;
  const cellWarningsByLine = groupByLineNumber(cells);
  const isAlertExist = cells.length > 0 || controller.length > 0;
  return (
    <ul className="rounded-10">
      {!isAlertExist && (
        <li className={clsx(alarmItemStyle, 'text-center')}>
          {t('alarm_no_exist_message')}
        </li>
      )}
      {controller.map((controllerWarning) => (
        <li
          className={alarmItemStyle}
          key={`controller_stop_${controllerWarning.controller_number}`}
        >
          <div className="flex items-center text-sm text-subText gap-x-1">
            <p>
              {getLocaleDateString(controllerWarning.datetime, locale, tGlobal)}
            </p>
            <svg
              width="2"
              height="13"
              viewBox="0 0 2 13"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M1 12.5L1 0.5" stroke="#ECECEC" />
            </svg>
            <p>
              {getLocaleTimeString(controllerWarning.datetime, locale, tGlobal)}
            </p>
          </div>
          <p className="font-bold">
            {t('_alarm_controller_stop_text', {
              number: controllerWarning.controller_number,
            })}
          </p>
        </li>
      ))}
      {Array.from(cellWarningsByLine.keys()).map((lineNumber) => {
        const cellWarnings = cellWarningsByLine.get(lineNumber);
        if (!cellWarnings || cellWarnings.length === 0) return null;
        const firstCellWarning = cellWarnings[0];
        return (
          <li className={alarmItemStyle} key={`cell_warn_line_${lineNumber}`}>
            <div className="flex items-center text-sm text-subText gap-x-1">
              <p>
                {getLocaleDateString(
                  firstCellWarning.datetime,
                  locale,
                  tGlobal,
                )}
              </p>
              <svg
                width="2"
                height="13"
                viewBox="0 0 2 13"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M1 12.5L1 0.5" stroke="#ECECEC" />
              </svg>
              <p>
                {getLocaleTimeString(
                  firstCellWarning.datetime,
                  locale,
                  tGlobal,
                )}
              </p>
            </div>
            <p className="font-bold">
              {t('_alarm_cells_line_stop_text', {
                number: lineNumber,
              })}
            </p>
            <div className="flex flex-wrap gap-x-2.5 gap-y-1 mt-1">
              {cellWarnings.map((cellWarning) => (
                <span
                  className={cellNumberingStyle}
                  key={`cell_warn_${cellWarning.cell_number}`}
                >
                  {cellWarning.block_number}-{cellWarning.floor_number}
                </span>
              ))}
            </div>
          </li>
        );
      })}
    </ul>
  );
}
// style
const dividerStyle = clsx(
  'after:absolute after:right-2 after:left-2 after:bottom-0 after:h-[1px] after:bg-subLine after:last:hidden',
  "after:content-['']",
);
const alarmItemStyle = clsx(
  'flex flex-col',
  'relative rounded-10',
  'p-5 lg:p-2.5',
  'text-subText',
  // 'hover:bg-subLine',
  dividerStyle,
);
const cellNumberingStyle = clsx(
  'px-1 py-0.5',
  'text-subText',
  'bg-subLine rounded-full',
);
// util
const groupByLineNumber = (
  cells: CellWarning[],
): Map<number, CellWarning[]> => {
  return cells.reduce((acc, cellWarning) => {
    const { line_number: lineNumber } = cellWarning;
    const existingGroup = acc.get(lineNumber) || [];
    acc.set(lineNumber, [...existingGroup, cellWarning]);
    return acc;
  }, new Map<number, CellWarning[]>());
};

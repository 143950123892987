import { useQuery } from '@tanstack/react-query';
import { requestRelay } from 'api/relay';
import { Access, RelayObject } from 'types/types';
import { RELAY_QUERY_KEY } from '../relayQuery';

type Target = {
  farmNumber: number;
  controllerNumber: number;
  access: Access;
};

export const Target_RELAY_QUERY_KEY = 'targetRelay';

export const useTargetRelayQuery = (target: Target) => {
  const { farmNumber, controllerNumber, access } = target;

  return useQuery({
    queryKey: [RELAY_QUERY_KEY, farmNumber, controllerNumber],
    queryFn: async () => {
      const relayResponse = await requestRelay({
        payload: { farmNumber, controllerNumber },
        access,
      });
      const relays: RelayObject[] = relayResponse.relay_setting.map(
        (relayItem) => {
          const relay = relayItem.relay;
          return {
            relayNumber: relay[0],
            relayType: relay[1],
            relayStatus: relay[2],
          };
        },
      );
      return relays;
    },
  });
};

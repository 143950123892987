import { IcProps } from '.';

const ICInsectHubLogo = (props: IcProps) => {
  const { width = 110, height = 36, color = '#42C67E' } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={`${width}`}
      height={`${height}`}
      viewBox="0 0 110 36"
      fill="none"
    >
      <g clipPath="url(#clip0_1665_15)">
        <path
          d="M40.5039 0.962891H43.5957V16.2272H40.5039V0.962891Z"
          fill={color}
        />
        <path
          d="M46.6875 0.962779H49.7793L54.2625 9.13458L55.8084 12.3725H55.963C55.8084 10.8306 55.4992 8.82621 55.4992 6.97599V0.808594H58.4364V16.0729H55.3446L51.0161 8.05529L49.4701 4.8174H49.3156C49.4701 6.51344 49.6247 8.36366 49.6247 10.2139V16.3813H46.6875V0.962779Z"
          fill={color}
        />
        <path
          d="M60.7559 14.2225L62.4564 12.0639C63.5385 13.1432 64.9298 13.7599 66.3212 13.7599C67.8671 13.7599 68.7946 13.1432 68.7946 12.0639C68.7946 10.9846 67.8671 10.522 66.4757 10.0595L64.4661 9.13436C62.9201 8.51762 61.3742 7.28414 61.3742 4.97137C61.3742 2.50441 63.5385 0.5 66.6303 0.5C68.4854 0.5 70.1859 1.27092 71.4227 2.50441L69.8768 4.35463C68.9492 3.5837 67.8671 3.12115 66.6303 3.12115C65.239 3.12115 64.4661 3.73789 64.4661 4.663C64.4661 5.74229 65.5482 6.20485 66.9395 6.6674L68.9492 7.43833C70.8043 8.20925 72.041 9.44273 72.041 11.6013C72.041 14.0683 69.8768 16.2269 66.4757 16.2269C64.3115 16.5352 62.3018 15.7643 60.7559 14.2225Z"
          fill={color}
        />
        <path
          d="M74.2051 0.962891H83.6351V3.42985H77.2969V6.97611H82.7076V9.59725H77.2969V13.7602H83.7897V16.3814H74.2051V0.962891Z"
          fill={color}
        />
        <path
          d="M85.6445 8.67192C85.6445 3.58381 88.8909 0.654297 92.7557 0.654297C94.7654 0.654297 96.3113 1.57941 97.2389 2.6587L95.693 4.66311C94.92 3.89218 93.9925 3.42963 92.9103 3.42963C90.5915 3.42963 88.8909 5.43403 88.8909 8.67192C88.8909 12.064 90.4369 14.0684 92.9103 14.0684C94.1471 14.0684 95.2292 13.4517 96.0021 12.5265L97.5481 14.3768C96.3113 15.9186 94.6108 16.6895 92.6011 16.6895C88.7364 16.5354 85.6445 13.76 85.6445 8.67192Z"
          fill={color}
        />
        <path
          d="M102.186 3.42985H97.8574V0.962891H109.606V3.42985H105.278V16.2272H102.186V3.42985Z"
          fill={color}
        />
        <path
          d="M40.5039 19.9277H43.5957V25.941H49.4702V19.9277H52.562V35.1921H49.4702V28.5621H43.5957V35.1921H40.5039V19.9277Z"
          fill={color}
        />
        <path
          d="M55.5 28.2536V19.7734H58.5918V28.4078C58.5918 31.6457 59.674 32.725 61.5291 32.725C63.3842 32.725 64.4663 31.6457 64.4663 28.4078V19.7734H67.4035V28.2536C67.4035 33.3417 65.2393 35.3461 61.5291 35.3461C57.8189 35.5003 55.5 33.3417 55.5 28.2536Z"
          fill={color}
        />
        <path
          d="M70.6484 19.9277H75.75C78.8418 19.9277 81.3152 20.8528 81.3152 23.6282C81.3152 25.0158 80.5423 26.4035 79.151 26.8661V27.0202C80.8515 27.4828 82.0882 28.5621 82.0882 30.5665C82.0882 33.6502 79.4601 35.0379 76.2137 35.0379H70.803V19.9277H70.6484ZM75.4408 26.0951C77.4505 26.0951 78.2234 25.3242 78.2234 24.0907C78.2234 22.7031 77.2959 22.2405 75.4408 22.2405H73.5857V26.0951H75.4408ZM75.75 32.8793C77.9142 32.8793 78.9964 32.1083 78.9964 30.5665C78.9964 29.0246 77.9142 28.4079 75.75 28.4079H73.5857V32.8793H75.75Z"
          fill={color}
        />
        <mask
          id="mask0_1665_15"
          style={{
            maskType: 'luminance',
          }}
          maskUnits="userSpaceOnUse"
          x="0"
          y="0"
          width="33"
          height="36"
        >
          <path d="M32.4642 0.5H0V35.5H32.4642V0.5Z" fill="white" />
        </mask>
        <g mask="url(#mask0_1665_15)">
          <path
            d="M25.6614 35.5C21.9512 35.5 18.8594 32.4163 18.8594 28.7158V7.28418C18.8594 3.58374 21.9512 0.5 25.6614 0.5C29.3716 0.5 32.4634 3.58374 32.4634 7.28418V28.7158C32.4634 32.4163 29.3716 35.5 25.6614 35.5Z"
            fill={color}
          />
          <path
            d="M0 18.694H6.95661C10.5122 18.694 13.4494 15.7644 13.4494 12.2182V7.13009C13.4494 3.58383 10.5122 0.654297 6.95661 0.654297H6.49283C2.93723 0.654297 0 3.58383 0 7.13009V18.694Z"
            fill={color}
          />
          <path
            d="M0 35.5003H6.95661C10.6668 35.5003 13.604 32.5707 13.604 29.0245C13.604 25.324 10.6668 22.3945 7.11121 22.3945H6.80201C3.09182 22.3945 0.154601 25.324 0.154601 29.0245V35.5003H0Z"
            fill={color}
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_1665_15">
          <rect
            width="109.605"
            height="35"
            fill="white"
            transform="translate(0 0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
export default ICInsectHubLogo;

import { UserType, WebData } from 'types/types';
import customAxios from './customAxios';
import { setItem } from 'utils/storage';

export type RequestWebDataResponse = {
  web_data: WebData[];
  isSuccess: boolean;
  code: number;
  message: string;
  user_type: UserType[];
  access_token: string;
  refresh_token: string;
};
export type RequestInsertWebDataResponse = {
  web_data: WebData[];
  isSuccess: boolean;
  code: number;
  message: string;
  user_type: UserType[];
  access_token: string;
  refresh_token: string;
};

type WebDataPayload = {
  idToken: string;
};

export async function requestWebData({ payload }: { payload: WebDataPayload }) {
  const { idToken } = payload;
  const requestBody = new FormData();
  requestBody.append('email', idToken);
  try {
    const response = await customAxios.post<RequestWebDataResponse>(
      `/web/web_data.php`,
      requestBody,
    );
    setItem('access_token', response.data.access_token);
    setItem('refresh_token', response.data.refresh_token);
    return response.data;
  } catch (error) {
    console.error('에러가 발생하였습니다. 다시 확인해 주십시오.');
    throw error;
  }
}

export async function requestInsertWebData(formData: FormData) {
  try {
    const response = await customAxios.post<RequestInsertWebDataResponse>(
      `/web/insert_web_data.php`,
      formData,
    );
    setItem('access_token', response.data.access_token);
    setItem('refresh_token', response.data.refresh_token);
    return response.data;
  } catch (error) {
    console.error('에러가 발생하였습니다. 다시 확인해 주십시오.');
    throw error;
  }
}

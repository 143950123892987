import { requestInsertWebData, requestWebData } from 'api/webdata';
import useControllerStore from 'store/controllerStore';
import useFarmStore from 'store/farmStore';
import useLocaleStore from 'store/localeStore';
import useLoginStore from 'store/loginStore';
import { Language, Locale } from 'types/types';
import { GoogleAuthProvider, signInWithPopup, signOut } from 'firebase/auth';
import { auth } from 'setting/fb';
import { removeItem } from '../utils/storage';
import useLayoutStore from 'store/layoutStore';

export default function useLogin() {
  const { setIsGoogleUser, setIsLoginSuccess, clearLogin, setIsLoginLoading } =
    useLoginStore();
  const { setControllerListMap } = useControllerStore();
  const { setFarmState } = useFarmStore();
  const { setLocale } = useLocaleStore(); //언어 전역 상태 설정
  const { setIsNavCollapsed } = useLayoutStore();
  const handleLogin = async (idToken: string) => {
    setIsGoogleUser(true);
    setIsLoginLoading(true);
    setIsNavCollapsed(true); //사이드바 접음
    try {
      const result = await requestWebData({ payload: { idToken } });
      if (result.isSuccess) {
        const farmNumbers = result.web_data[0].selected_id; //해당 유저가 보유 중인 farmNumber 배열
        const userTypes = result.user_type;
        setIsLoginSuccess(true);
        setFarmState(result.web_data[0], userTypes);
        setControllerListMap(result.web_data[0].controller_number, farmNumbers);
        setLocale(getLocale(result.web_data[0].language));
      } else {
        setIsLoginSuccess(false);
      }
      setIsLoginLoading(false);
      return result;
    } catch (error) {
      /**#1 error 핸들링 필요 */
      console.error(error);
      setIsLoginLoading(false);
      throw error;
    }
  };
  const handleSignUp = async (user: string, email: string) => {
    const requestBody = new FormData();
    requestBody.append('user', user);
    requestBody.append('email', email);
    try {
      const result = await requestInsertWebData(requestBody);
      if (result.isSuccess) {
        const farmNumbers = result.web_data[0].selected_id; //해당 유저가 보유 중인 farmNumber 배열
        const userTypes = result.user_type;
        setFarmState(result.web_data[0], userTypes);
        setIsLoginSuccess(true);
        setControllerListMap(result.web_data[0].controller_number, farmNumbers);
        setLocale(getLocale(result.web_data[0].language));
      } else {
        alert('회원가입에 실패하였습니다. 반달소프트에 연락 바랍니다.');
      }
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  const handleLogout = async () => {
    try {
      await signOut(auth);
      clearLogin();
      removeItem('access_token');
      removeItem('refresh_token');
    } catch (error) {
      /* */
    }
  };

  const handleGoogleLogin = () => {
    const provider = new GoogleAuthProvider(); // provider를 구글로 설정
    signInWithPopup(auth, provider) // Redirect를 이용한 isLoggedIn
      .then(async (result) => {
        const credential = GoogleAuthProvider.credentialFromResult(result);
        const idToken = credential?.idToken;
        idToken && (await handleLogin(idToken));
      })
      .catch((err) => {
        console.error(err);
      });
  };
  return { handleSignUp, handleLogin, handleLogout, handleGoogleLogin };
}

const getLocale = (language: Language): Locale => {
  switch (language) {
    case 1:
      return 'KR';
    case 2:
      return 'ENG';
    case 3:
      return 'VNM';
    default:
      return 'KR';
  }
};

import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { getUser, getUsers } from 'api/master/getUser';
import { insertUser } from 'api/master/insertUser';
import { updateUser } from 'api/master/updateUser';
import { IS_PRODUCTION } from 'constants/index';

//DB에 있는 모든 유저 목록을 쿼리하는 쿼리 훅
export const MASTER_USER_QUERY_KEY = 'userList';
export const useAllUserListQuery = () => {
  return useQuery({
    queryKey: [MASTER_USER_QUERY_KEY],
    queryFn: async () => {
      const users = await getUsers({ access: 0 }); //0번 서버 유저목록 조회
      return users;
    },
  });
};

//id로 유저를 조회하는 쿼리 훅
export const useUserQuery = (id: number) => {
  return useQuery({
    queryKey: [MASTER_USER_QUERY_KEY, id],
    queryFn: async () => {
      const user = await getUser({ access: 0, id });
      return user;
    },
  });
};

//유저를 추가하는 쿼리 훅 (0번 서버에 추가)
export const useInsertUserMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (
      payload: Parameters<typeof insertUser>[0]['payload'],
    ) => {
      const res = await insertUser({ payload });
      if (res.isSuccess === false) {
        throw new Error(res.message);
      }
    },
    onSuccess: () => {
      queryClient.refetchQueries([MASTER_USER_QUERY_KEY]);
    },
  });
};

//유저를 수정하는 쿼리 훅
export const useUpdateUserMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (payload: Parameters<typeof updateUser>[0]) => {
      const res = await updateUser(payload);
      if (res.isSuccess === false) {
        throw new Error(res.message);
      }
    },
    onSuccess: () => {
      queryClient.refetchQueries([MASTER_USER_QUERY_KEY]);
    },
  });
};

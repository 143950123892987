import useFarmStore from 'store/farmStore';
import FarmNumberSelect from './FarmNumberSelect';
import StatisticArea from './StatisticArea';
import clsx from 'clsx';
import ICInsectHubLogo from 'components/icon/IcInsectHubLogo';
import AsyncBoundary from 'components/common/AsyncBoundary';
import useNavStore from 'store/navStore';
import {
  RackTypeLandingId,
  HubTypeLandingId,
  RailTypeLandingId,
} from 'setting/navList';
import ControllerSelect from './ControllerSelect';
import { isHubType, isRackType, isRailType } from 'utils/userType';
import Alarm from '../alarm/Alarm';

export const headerContainerClass = clsx(
  'fixed top-0 flex w-screen bg-white h-headerHeight py-2.5 z-30',
  'px-30px',
  'shadow-main',
  'whitespace-nowrap select-none',
);
export const logoClass = clsx('w-8', 'object-cover', 'lg:w-10');

export default function EnvironmentStatusHeader() {
  const { setSelectedNavId } = useNavStore();
  const { currentFarmNumber: farmNumber, userTypes } = useFarmStore();
  const currentUserType = userTypes[farmNumber];
  const isStatisticsAvailable = isHubType(currentUserType);
  const landingNavId = (() => {
    if (isRackType(currentUserType)) return RackTypeLandingId;
    if (isRailType(currentUserType)) return RailTypeLandingId;
    if (isHubType(currentUserType)) return HubTypeLandingId;
    return -1;
  })();

  return (
    <div className={clsx(headerContainerClass)}>
      <div className="relative flex items-center gap-x-4">
        <div className="flex items-center ">
          <div
            className={'cursor-pointer'}
            onClick={() => {
              setSelectedNavId(landingNavId);
            }}
          >
            <ICInsectHubLogo />
          </div>
          <div className="flex gap-x-2.5 items-center ml-10">
            <Alarm />
            <AsyncBoundary>
              <FarmNumberSelect />
            </AsyncBoundary>
            {isStatisticsAvailable && (
              <AsyncBoundary>
                <ControllerSelect />
              </AsyncBoundary>
            )}
          </div>
        </div>
        {isStatisticsAvailable && (
          <AsyncBoundary>
            <StatisticArea />
          </AsyncBoundary>
        )}
      </div>
    </div>
  );
}

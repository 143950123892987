import { ControllerList, FarmControllerListMap } from 'types/types';
import { create } from 'zustand';
import { createJSONStorage, devtools, persist } from 'zustand/middleware';

interface ControllerState {
  currentControllerNumber: number;
  controllerListMap: FarmControllerListMap;
  setCurrentControllerNumber: (newControllerNumber: number) => void;
  setControllerListMap: (
    newControllerLists: ControllerList[],
    farmNumbers: number[],
  ) => void;
}

const useControllerStore = create<ControllerState>()(
  devtools(
    persist(
      (set) => ({
        currentControllerNumber: 1,
        controllerListMap: {},
        setCurrentControllerNumber: (newControllerNumber: number) =>
          set({ currentControllerNumber: newControllerNumber }),
        setControllerListMap: (newControllerLists, farmNumbers) => {
          const newControllerListMap: FarmControllerListMap = {};
          farmNumbers.forEach(
            (farmNumber, index) =>
              (newControllerListMap[farmNumber] = newControllerLists[index]),
          );
          set(() => ({ controllerListMap: newControllerListMap }));
        },
      }),
      {
        name: 'controller-store',
        storage: createJSONStorage(() => sessionStorage),
      },
    ),
  ),
);
export default useControllerStore;

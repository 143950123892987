import { useEffect, useState } from 'react';
import useLocaleStore from 'store/localeStore';
import useLoginStore from 'store/loginStore';
import { isLocale } from 'types/types';
import { auth } from 'setting/fb';

// 초기 설정을 위한 커스텀 훅
export default function useInit() {
  const { setIsGoogleUser, setEmail } = useLoginStore();
  const [userName, setUserName] = useState<string>('');
  const { locale, isEnglish, isVietnamese, setLocale } = useLocaleStore(); // 현재 언어 설정 가져오기

  useEffect(() => {
    switch (locale) {
      case 'ENG':
        document.title = 'InsectHub';
        break;
      case 'KR':
        document.title = '인섹트허브';
        break;
      // 다른 언어 설정에 대한 케이스 추가
      default:
        document.title = 'InsectHub';
    }
  }, [locale]); // locale 값이 변경될 때마다 실행

  // 언어 설정을 가져오는 이펙트
  useEffect(() => {
    // URLSearchParams를 사용하여 쿼리 파라미터에서 언어 설정을 가져옴
    const params = new URLSearchParams(window.location.search);
    const language = params.get('lang');
    if (!language) return; // 언어 설정이 없으면 기본값으로 한국어 설정
    const locale = language.toUpperCase();
    if (isLocale(locale)) {
      setLocale(locale);
    }
  }, [setLocale]);

  useEffect(() => {
    auth.onAuthStateChanged((user) => {
      if (user) {
        user.email && setEmail(user.email);
        user.displayName && setUserName(user.displayName);
        setIsGoogleUser(true);
      } else {
        setIsGoogleUser(false);
      }
    });
  }, [setEmail, setIsGoogleUser]);

  return {
    userName,
    setEmail,
    setIsGoogleUser,
    setUserName,
    locale,
    isEnglish,
    isVietnamese,
    setLocale,
  };
}

import { DefaultResponse } from 'api';
import { Access, Auth, Language } from 'types/types';
import { masterAuthUrl } from '.';
import customAxios from 'api/customAxios';

type Payload = {
  access: Access;
  user: string; // 사용자 이름
  farmNumber: number; // 농장 번호 : selected_id
  auth: Auth; // 권한
  language: Language; // 언어 (한국어, 영어, 베트남어)
  email: string; // 이메일
};

/**
 * 이미 존재하는 사용자에게 소유 농장을 추가합니다.
 * @param param0
 * @returns
 */
export const insertUser = async ({ payload }: { payload: Payload }) => {
  const requestBody = new FormData();
  requestBody.append('user', payload.user);
  requestBody.append('selected_id', payload.farmNumber.toString());
  requestBody.append('auth', `${payload.auth}`);
  requestBody.append('language', `${payload.language}`);
  requestBody.append('email', payload.email);
  requestBody.append('access', `${payload.access}`);

  try {
    //0번 서버에 사용자 추가 요청
    const response = await customAxios.post<DefaultResponse>(
      `${masterAuthUrl(0)}/insertUser.php`,
      requestBody,
    );
    if (response.data.isSuccess === false) {
      throw new Error(response.data.message);
    }
    return response.data;
  } catch (error) {
    console.error('Request Master Auth Insert User error', error);
    throw error;
  }
};

import Modal from 'components/common/Modal';
import { useState } from 'react';
import FarmAddForm from './FarmAddForm';

export default function FarmAddButton() {
  const [openModal, setOpenModal] = useState(false);
  const onClick = () => {
    setOpenModal(true);
  };
  const onClose = () => setOpenModal(false);
  return (
    <>
      <div className="flex justify-center" onClick={onClick}>
        <button className="text-sm font-bold text-mainGreen">
          농장 추가 +
        </button>
      </div>
      <Modal open={openModal} onClose={onClose}>
        <FarmAddForm onClose={onClose} />
      </Modal>
    </>
  );
}

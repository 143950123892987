export const RELAY_TEXT = {
  0: 'relay_none',
  1: 'relay_fan',
  2: 'relay_heater',
  3: 'relay_air_conditioner',
  4: 'relay_dehumidifier',
  5: 'relay_humidifier',
  6: 'relay_air_circulator',
  7: 'relay_watering_device',
  8: 'relay_feeding_device',
  9: 'relay_light',
};

export const RELAY_TYPE = {
  NONE: 0,
  FAN: 1,
  HEATER: 2,
  AIR_CONDITIONER: 3,
  DEHUMIDIFIER: 4,
  HUMIDIFIER: 5,
  AIR_CIRCULATOR: 6,
  WATERING_DEVICE: 7,
  FEEDING_DEVICE: 8,
  LIGHT: 9,
};

import { IcProps } from '.';

export const IcAlarmBell = ({ width = 17, height = 18 }: IcProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 17 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16.5857 12.1114C15.8736 10.8867 15.4955 9.12879 15.4955 7.03125C15.4955 5.20374 14.7695 3.45108 13.4773 2.15884C12.185 0.8666 10.4324 0.140625 8.60485 0.140625C6.77734 0.140625 5.02469 0.8666 3.73244 2.15884C2.4402 3.45108 1.71423 5.20374 1.71423 7.03125C1.71423 9.12961 1.3377 10.8867 0.625671 12.1114C0.480378 12.3612 0.403362 12.6449 0.402354 12.9339C0.401346 13.2228 0.476382 13.507 0.619929 13.7578C0.762567 14.0088 0.969599 14.2171 1.21965 14.3613C1.4697 14.5055 1.75371 14.5803 2.04235 14.5781H5.01024C5.09164 15.4752 5.50556 16.3095 6.17068 16.917C6.8358 17.5245 7.70405 17.8614 8.60485 17.8614C9.50566 17.8614 10.3739 17.5245 11.039 16.917C11.7041 16.3095 12.1181 15.4752 12.1995 14.5781H15.1674C15.4556 14.5799 15.7391 14.5048 15.9887 14.3607C16.2382 14.2165 16.4449 14.0084 16.5873 13.7578C16.7315 13.5074 16.8072 13.2234 16.8069 12.9344C16.8066 12.6454 16.7303 12.3616 16.5857 12.1114ZM8.60485 15.8906C8.22664 15.8907 7.86001 15.7601 7.56702 15.5209C7.27404 15.2818 7.07268 14.9487 6.99704 14.5781H10.2127C10.137 14.9487 9.93567 15.2818 9.64268 15.5209C9.34969 15.7601 8.98307 15.8907 8.60485 15.8906ZM2.58868 12.6094C3.31466 11.1328 3.68298 9.25758 3.68298 7.03125C3.68298 5.72589 4.20153 4.47399 5.12456 3.55096C6.04759 2.62793 7.29949 2.10937 8.60485 2.10937C9.91021 2.10937 11.1621 2.62793 12.0851 3.55096C13.0082 4.47399 13.5267 5.72589 13.5267 7.03125C13.5267 9.25676 13.8942 11.1328 14.6202 12.6094H2.58868Z"
      fill="#282828"
    />
  </svg>
);

import { MAIN_GREEN, SUB_LINE } from 'constants/color';
import Select, { StylesConfig } from 'react-select';
import { Access, isAccess } from 'types/types';
import { AccessList } from 'utils/access';
import { OptionType } from 'utils/select';

type Props = {
  selectedAccess?: Access;
  onSelect: (value: Access) => void;
};

export default function AccessSelect({ selectedAccess, onSelect }: Props) {
  const options: OptionType[] = AccessList.map((access) => ({
    value: `${access.id}`,
    label: `${access.id}-${access.name}`,
  }));

  return (
    <Select
      className="flex flex-1 h-full"
      styles={accessSelectStyle}
      options={options}
      value={options.find((open) => open.value === `${selectedAccess}`)}
      onChange={(selectedOption) => {
        if (!selectedOption) return;
        const selectedAccess = Number(selectedOption.value);
        isAccess(selectedAccess) && onSelect(selectedAccess);
      }}
      components={{
        IndicatorSeparator: () => null,
      }}
    />
  );
}

// react select style
export const accessSelectStyle: StylesConfig<OptionType, false> = {
  option: (provided) => ({
    ...provided,
    opacity: 0.8,
    // backgroundColor: state.isFocused ? colorMainGreen : undefined,
  }),
  control: (provided, state) => ({
    ...provided,
    flex: 1,
    borderColor: SUB_LINE,
    outlineColor: state.isFocused ? MAIN_GREEN : undefined,
    boxShadow: 'none',
    borderRadius: 6,
    height: 45,
    padding: '0 10px',
    ':hover': {
      outlineColor: SUB_LINE,
      borderColor: SUB_LINE,
    },
    ':focus': {
      borderColor: SUB_LINE,
      outlineColor: SUB_LINE,
    },
  }),
  singleValue: (provided) => ({
    ...provided,
  }),
  valueContainer: (provided) => ({
    ...provided,
    padding: 0,
  }),
  placeholder: (provided) => ({
    ...provided,
    color: '#7D7B7B',
    fontSize: 14,
    fontWeight: 400,
  }),
  indicatorSeparator: (provided) => ({ ...provided, display: 'none' }),
};

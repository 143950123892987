import {
  Access,
  AccessMap,
  Auth,
  UserType,
  UserTypeMap,
  WebData,
} from 'types/types';
import { create } from 'zustand';
import { createJSONStorage, devtools, persist } from 'zustand/middleware';

interface farmState {
  farmNumbers: number[];
  currentFarmNumber: number;
  access: Access; //현재 농장에 해당하는 서버 번호
  accessMap?: AccessMap; //각 농장 번호에 따른 서버 번호 목록
  auth: Auth;
  userTypes: UserTypeMap;
  setFarmNumbers: (value: number[]) => void;
  changeFarmNumber: (value: number) => void; //현재 농장 번호 변경
  setUserTypes: (farmNumbers: number[], userTypes: UserType[]) => void;
  setFarmState: (webData: WebData, userTypes: UserType[]) => void;
}

const useFarmStore = create<farmState>()(
  devtools(
    persist(
      (set) => ({
        auth: 0,
        farmNumbers: [],
        currentFarmNumber: 0,
        access: 0,
        accessMap: {},
        userTypes: {},
        setFarmNumbers: (value) => set(() => ({ farmNumbers: value })),
        changeFarmNumber: (newFarmNumber) =>
          set((state) => ({
            currentFarmNumber: newFarmNumber, //현재 농장 번호 변경
            access: state.accessMap
              ? state.accessMap[newFarmNumber]
              : state.access, //서버 번호 변경
          })),
        setUserTypes: (farmNumbers, userTypes) => {
          const newUserTypes: UserTypeMap = {};
          farmNumbers.forEach(
            (farmNumber, index) =>
              (newUserTypes[farmNumber] = userTypes[index]),
          );
          set(() => ({ userTypes: newUserTypes }));
        },
        setFarmState: (webData, userTypes) => {
          const access = webData.access;
          const farmNumbers = webData.selected_id;
          const newUserTypes: UserTypeMap = {};
          farmNumbers.forEach(
            (farmNumber, index) =>
              (newUserTypes[farmNumber] = userTypes[index]),
          );
          const accessMap: AccessMap = {};
          farmNumbers.forEach(
            (farmNumber, index) =>
              (accessMap[farmNumber] =
                typeof webData.access === 'number'
                  ? webData.access
                  : webData.access[index]),
          );
          set(() => ({
            access: typeof access === 'number' ? access : access[0],
            accessMap: accessMap,
            currentFarmNumber: farmNumbers[0],
            farmNumbers: farmNumbers,
            userTypes: newUserTypes,
            auth: webData.auth,
          }));
        },
      }),
      { name: 'farm-store', storage: createJSONStorage(() => sessionStorage) },
    ),
  ),
);

export default useFarmStore;

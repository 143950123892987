import { useController } from 'hooks/controller/useController';
import clsx from 'clsx';
import useTranslate from 'hooks/useTranslate';

export default function StatisticArea() {
  const t = useTranslate();
  const { controller } = useController();

  return (
    <div className="relative flex items-center gap-5">
      <div
        className={clsx(
          'flex items-center gap-x-2.5 px-1 py-1 overflow-x-auto',
        )}
      >
        <div className={`${wrapperClass}`}>
          <span className="text-sm text-subText">{t('온도')}</span>
          <span className="text-lg font-bold lg:text-xl">
            <span className="mr-0.5">{controller?.temperature}</span>℃
          </span>
        </div>
        <div className={`${wrapperClass}`}>
          <span className="text-sm text-subText">{t('습도')}</span>
          <span className="text-lg font-bold lg:text-xl">
            <span className="mr-0.5">{controller?.humidity}</span>%
          </span>
        </div>
        <div className={`${wrapperClass}`}>
          <span className="text-sm text-subText">{t('이산화탄소')}</span>
          <span className="text-lg font-bold lg:text-xl">
            <span className="mr-0.5">{controller?.co2}</span>
            ppm
          </span>
        </div>
        <div className="flex flex-col items-center   py-2.5 px-5">
          <span className="text-sm text-subText">VOC</span>
          <span className="text-lg font-bold lg:text-xl">
            {controller?.voc}
          </span>
        </div>

        <div className="flex flex-col items-center   py-2.5 px-5">
          <span className="text-sm text-subText">pm2.5</span>
          <span className="text-lg font-bold lg:text-xl">
            <span className="mr-0.5">{controller?.pm25}</span>㎍/m³
          </span>
        </div>
      </div>
    </div>
  );
}
const wrapperClass = clsx(
  'flex flex-col items-center',
  'px-30px py-3.5',
  'px-1',
);

import clsx from 'clsx';
import { useInsertUserMutation } from 'hooks/reactQuery/master/usersQuery';
import React from 'react';
import useUserAddFormStore from 'store/master/userAddFormStore';
import { Auth, isAccess, Language } from 'types/types';

type Props = {
  onClose: () => void;
};
export default function UserAddForm({ onClose }: Props) {
  const {
    name,
    farmId,
    language,
    auth,
    email,
    access,
    setName,
    setFarmId,
    setLanguage,
    setAuth,
    setEmail,
    setAccess,
    init,
  } = useUserAddFormStore();
  const { mutateAsync } = useInsertUserMutation();
  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (access === undefined) return;
    await mutateAsync({
      user: name,
      farmNumber: farmId,
      language,
      auth,
      email,
      access,
    });
    onClose();
  };
  const onReset = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    init();
    onClose();
  };

  return (
    <form
      id="master-main-user-add-info"
      className="flex flex-col gap-y-3"
      onSubmit={onSubmit}
      onReset={onReset}
    >
      <h2 className="text-lg font-bold">회원 추가</h2>
      <div className="grid grid-cols-2 gap-x-30px gap-y-1">
        <div className="flex gap-x-0.5 items-center col-span-2">
          <label htmlFor="user-add-name" className={labelStyle}>
            성함
          </label>
          <input
            id="farm-add-name"
            className={inputStyle}
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </div>
        <div className="flex gap-x-0.5 items-center">
          <label htmlFor="user-add-farm-id" className={labelStyle}>
            농장번호
          </label>
          <input
            id="user-add-farm-id"
            className={inputStyle}
            value={farmId}
            type="number"
            onChange={(e) => {
              const value = parseInt(e.target.value);
              if (isNaN(value)) return;
              setFarmId(value);
            }}
          />
        </div>
        <div className="flex gap-x-0.5 items-center">
          <label htmlFor="user-add-language" className={labelStyle}>
            언어
          </label>
          <input
            id="user-add-language"
            className={inputStyle}
            type="number"
            value={language}
            onChange={(e) => {
              const value = parseInt(e.target.value);
              if (isNaN(value) || value < 0 || value > 2) return;
              setLanguage(value as Language);
            }}
          />
        </div>
        <div className="flex gap-x-0.5 items-center">
          <label htmlFor="user-add-access" className={labelStyle}>
            서버번호
          </label>
          <input
            id="user-add-access"
            className={inputStyle}
            type="number"
            value={access}
            onChange={(e) => {
              const newAccess = parseInt(e.target.value);
              if (isNaN(newAccess) || !isAccess(newAccess)) return;
              setAccess(newAccess);
            }}
          />
        </div>

        <div className="flex gap-x-0.5 items-center">
          <label htmlFor="user-add-auth" className={labelStyle}>
            권한
          </label>
          <input
            id="user-add-auth"
            className={inputStyle}
            type="number"
            value={auth}
            onChange={(e) => {
              const value = parseInt(e.target.value);
              if (isNaN(value)) return;
              setAuth(value as Auth);
            }}
          />
        </div>
        <div className="flex gap-x-0.5 items-center col-span-2 ">
          <label htmlFor="user-add-email" className={labelStyle}>
            이메일
          </label>
          <input
            id="user-add-email"
            className={inputStyle}
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </div>
      </div>
      <div className="flex gap-x-2.5">
        <button
          type="reset"
          className={clsx(buttonStyle, 'text-subText bg-subLine flex-[0.3]')}
        >
          취소
        </button>
        <button
          type="submit"
          className={clsx(buttonStyle, 'text-white bg-mainGreen flex-[0.7]')}
        >
          생성
        </button>
      </div>
    </form>
  );
}

// style
const labelStyle = clsx('text-sm text-subText min-w-[70px] whitespace-nowrap');
const inputStyle = clsx(
  'border-subLine border rounded-md px-2.5 py-3 flex-1',
  'focus:outline-none',
  'font-bold text-sm',
  'placeholder:font-normal',
);
const buttonStyle = clsx('px-3 py-2.5 rounded-md text-sm font-medium');

import { useAllFarmListQuery } from 'hooks/reactQuery/master/allFarmListQuery';
import FarmItem from './FarmItem';
import useFarmListPageStore from 'store/master/farmListPageStore';

export default function FarmItems() {
  const { data: farms } = useAllFarmListQuery();
  const { currentPage, farmPerPage } = useFarmListPageStore();

  if (!farms) return <div className="bg-white lg:h-[612px]" />;

  const start = (currentPage - 1) * farmPerPage;
  const end = currentPage * farmPerPage;

  const slicedFarms = farms.slice(start, end);
  return (
    <div className="flex flex-col">
      {slicedFarms.map((farm, index) => (
        <FarmItem key={index} farm={farm} />
      ))}
    </div>
  );
}

import clsx from 'clsx';
import { headerContainerClass } from 'components/header/Header';
import ICInsectHubLogo from 'components/icon/IcInsectHubLogo';
import useResponsiveUI from 'hooks/layout/useResponsiveUI';
import { Link } from 'react-router-dom';

//관리자 페이지 헤더
export default function MasterHeader() {
  const { isMobile } = useResponsiveUI();
  return (
    <div className={isMobile ? mobileHeaderStyle : headerContainerClass}>
      <div className="flex items-center gap-x-30px">
        <Link to="/">
          <ICInsectHubLogo width={isMobile ? 68 : undefined} />
        </Link>
        <div className="flex justify-center gap-x-[30px]">
          <Link to="/master/farm">
            <span className="font-bold">농장 리스트</span>
          </Link>
          <Link to="/master/user">
            <span className="font-bold">회원 관리</span>
          </Link>
        </div>
      </div>
    </div>
  );
}

const mobileHeaderStyle = clsx(
  'w-screen h-[60px] fixed top-0 left-0 z-50 bg-white shadow-md px-15px',
  'flex items-center',
);

import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import {
  requestRelay,
  requestUpdateRelayStatus,
  requestUpdateRelayType,
} from 'api/relay';
import useControllerStore from 'store/controllerStore';
import useFarmStore from 'store/farmStore';
import { RelayObject } from 'types/types';

export const RELAY_QUERY_KEY = 'relay';

// 릴레이 정보를 가져오는 쿼리
export const useRelayQuery = () => {
  const { currentFarmNumber: farmNumber, access } = useFarmStore();
  const { currentControllerNumber: controllerNumber } = useControllerStore();

  return useQuery({
    queryKey: [RELAY_QUERY_KEY, farmNumber, controllerNumber],
    queryFn: async () => {
      const relayResponse = await requestRelay({
        payload: { farmNumber, controllerNumber },
        access,
      });
      const relays: RelayObject[] = relayResponse.relay_setting.map(
        (relayItem) => {
          const relay = relayItem.relay;
          return {
            relayNumber: relay[0],
            relayType: relay[1],
            relayStatus: relay[2],
          };
        },
      );
      return relays;
    },
  });
};

// 농장에 보유중인 모든 컨트롤러에 연결된 릴레이 정보를 가져오는 쿼리
export const useMultiRelayQuery = () => {
  const { currentFarmNumber: farmNumber, access } = useFarmStore();
  const { controllerListMap } = useControllerStore();
  const controllerList = controllerListMap[farmNumber];
  return useQuery({
    queryKey: [RELAY_QUERY_KEY, farmNumber],
    queryFn: async () => {
      //모든 컨트롤러에 대한 릴레이 정보를 가져옴
      const relayListResponses = await Promise.all(
        controllerList.map(async (controllerNumber) => {
          return await requestRelay({
            payload: { farmNumber, controllerNumber },
            access,
          });
        }),
      );
      const relaySettings = relayListResponses.map((res) => res.relay_setting);
      return relaySettings.map((relaySetting) => {
        return relaySetting.map((relayItem) => {
          const relay = relayItem.relay;
          return {
            relayNumber: relay[0],
            relayType: relay[1],
            relayStatus: relay[2],
          } as RelayObject;
        }) as RelayObject[];
      });
    },
  });
};

export const useUpdateRelayStatusMutation = () => {
  const { currentFarmNumber: farmNumber } = useFarmStore();
  const { currentControllerNumber: controllerNumber } = useControllerStore();

  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: requestUpdateRelayStatus,
    onSuccess: () => {
      queryClient.invalidateQueries([
        RELAY_QUERY_KEY,
        farmNumber,
        controllerNumber,
      ]);
    },
  });
};

export const useUpdateRelayTypeMutation = (
  farmNumber: number,
  controllerNumber: number,
) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: requestUpdateRelayType,
    onSuccess: () => {
      queryClient.invalidateQueries([
        RELAY_QUERY_KEY,
        farmNumber,
        controllerNumber,
      ]);
    },
  });
};

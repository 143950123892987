import { Access } from 'types/types';
import { create } from 'zustand';

interface FarmAddFormState {
  country: string;
  name: string;
  address: string;
  access: Access | undefined;
  setCountry: (newCountry: string) => void;
  setName: (newName: string) => void;
  setAddress: (newAddress: string) => void;
  setAccess: (newAccess: Access) => void;
  init: () => void;
}

const useFarmAddFormStore = create<FarmAddFormState>((set) => ({
  country: '',
  name: '',
  address: '',
  access: undefined,
  setCountry: (newCountry: string) => set({ country: newCountry }),
  setName: (newName: string) => set({ name: newName }),
  setAddress: (newAddress: string) => set({ address: newAddress }),
  setAccess: (newAccess: Access) => set({ access: newAccess }),
  init: () => set({ country: '', name: '', address: '', access: undefined }),
}));

export default useFarmAddFormStore;

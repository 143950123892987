import { CellType } from 'types/types';

export const CELL_TYPES: { [key in CellType]: string } = {
  1: '귀뚜라미',
  2: '풀무치',
  3: '동애등에',
  4: '밀웜',
  5: '굼벵이',
};

export const CELL_TYPES_KEY: { [key in CellType]: string } = {
  1: 'global_cricket',
  2: 'global_grasshoper',
  3: 'global_bsf',
  4: 'global_mealworm',
  5: 'global_larvav',
};

export const CELL_STATUS: { [key in 'stop' | 'run' | 'empty']: number } = {
  stop: 0,
  run: 1,
  empty: 2,
};

export const CELL_TYPES_MAP: { [key: string]: CellType } = {
  cricket: 1,
  grasshoper: 2,
  bsf: 3,
  mealworm: 4,
  larvav: 5,
};

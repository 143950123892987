import { create } from 'zustand';
import { createJSONStorage, devtools, persist } from 'zustand/middleware';

interface LoginState {
  email: string;
  isGoogleUser: boolean;
  isLoginSuccess: boolean;
  isLoginLoading: boolean;
  setEmail: (email: string) => void;
  setIsGoogleUser: (value: boolean) => void;
  setIsLoginSuccess: (value: boolean) => void;
  setLoginState: (email: string) => void;
  setIsLoginLoading: (value: boolean) => void;
  clearLogin: () => void; //로그아웃 시에 사용
}

const useLoginStore = create<LoginState>()(
  devtools(
    persist(
      (set) => ({
        email: '',
        isGoogleUser: false,
        isLoginSuccess: false,
        isLoginLoading: false,
        clearLogin: () =>
          set(() => ({
            email: '',
            isGoogleUser: false,
          })),
        setEmail: (email) => set({ email: email }),
        setIsGoogleUser: (value) => set(() => ({ isGoogleUser: value })),
        setIsLoginSuccess: (value) => set({ isLoginSuccess: value }),
        setLoginState: (email) =>
          set({
            email: email,
          }),
        setIsLoginLoading: (value) => set(() => ({ isLoginLoading: value })),
      }),
      { name: 'user-store', storage: createJSONStorage(() => sessionStorage) },
    ),
  ),
);

export default useLoginStore;

/**
 * zustand 상태관리
 * 성함
 * 농장번호
 * 언어
 * 권한
 * 이메일
 */
import { create } from 'zustand';
import { Access, Auth, Language } from 'types/types';

interface UserAddFormState {
  name: string;
  farmId: number;
  language: Language;
  auth: Auth;
  email: string;
  access: Access | undefined;
  setName: (newName: string) => void;
  setFarmId: (newFarmId: number) => void;
  setLanguage: (newLanguage: Language) => void;
  setAuth: (newAuth: Auth) => void;
  setEmail: (newEmail: string) => void;
  setAccess: (newAccess: Access) => void;
  init: () => void;
}

const useUserAddFormStore = create<UserAddFormState>((set) => ({
  name: '',
  farmId: 0,
  language: 1,
  auth: 0,
  email: '',
  access: undefined,
  setName: (newName: string) => set({ name: newName }),
  setFarmId: (newFarmId: number) => set({ farmId: newFarmId }),
  setLanguage: (newLanguage: Language) => set({ language: newLanguage }),
  setAuth: (newAuth: Auth) => set({ auth: newAuth }),
  setEmail: (newEmail: string) => set({ email: newEmail }),
  setAccess: (newAccess: Access) => set({ access: newAccess }),
  init: () =>
    set({
      name: '',
      farmId: 0,
      auth: 0,
      access: 0,
      email: '',
    }),
}));

export default useUserAddFormStore;

import { useIsMasterQuery } from 'hooks/reactQuery/master/allFarmListQuery';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

function withMasterAuth<T extends object>(
  WrappedComponent: React.ComponentType<T>,
) {
  return function WithMasterCheck(props: T) {
    const navigate = useNavigate();
    // 마스터 확인 권한
    const { data: isMaster } = useIsMasterQuery();

    useEffect(() => {
      // 마스터 권한이 없으면 리다이렉트
      if (!isMaster) {
        navigate('/'); // 권한이 없으면 "/" 경로로 리다이렉트
      }
    }, [isMaster, navigate]);

    // 마스터일 경우에만 감싼 컴포넌트를 렌더링
    return isMaster ? <WrappedComponent {...props} /> : <></>;
  };
}

export default withMasterAuth;

import ICInsectHubLogo from 'components/icon/IcInsectHubLogo';
import { useEffect } from 'react';

export default function InAppBrowserContents() {
  const useragt = navigator.userAgent.toLowerCase();
  const isIosInApp = useragt.match(/iPhone|iPad/i);

  function openInDefaultBrowser() {
    // 새 탭에서 URL을 엽니다.
    if (navigator.userAgent.match(/iPhone|iPad/i)) {
      // IOS
      navigator.clipboard.writeText(window.location.href);
      location.href = 'x-web-search://?';
      /*
       현재 주소를 클립보드에 복사 후
       사파리 강제 실행
      */
    } else {
      // 안드로이드
      location.href =
        'intent://' +
        location.href.replace(/https?:\/\//i, '') +
        '#Intent;scheme=https;package=com.android.chrome;end';
      /*
        페이지를 강제 이동시켜서 크롬으로 URL을 열 수 있게 가능
        안드로이드의 intent 속성
        안드로이드폰에 크롬이 이미 내장되어 있어 브라우저를 크롬 패키지로 설정
      */
    }
  }
  useEffect(() => {
    const target_url = location.href;

    if (useragt.match(/kakaotalk/i)) {
      //카카오톡 외부브라우저로 호출
      location.href =
        'kakaotalk://web/openExternal?url=' + encodeURIComponent(target_url);
    } else if (useragt.match(/line/i)) {
      //라인 외부브라우저로 호출
      if (target_url.indexOf('?') !== -1) {
        location.href = target_url + '&openExternalBrowser=1';
      } else {
        location.href = target_url + '?openExternalBrowser=1';
      }
    } else {
      // 안드로이드
      location.href =
        'intent://' +
        location.href.replace(/https?:\/\//i, '') +
        '#Intent;scheme=https;package=com.android.chrome;end';
    }
  }, [useragt]);

  if (isIosInApp)
    return (
      <main>
        <div className="relative flex-col w-screen h-screen max-w-3xl mx-auto flexCenter gap-y-2">
          <div className="absolute top-4 left-2">
            <ICInsectHubLogo />
          </div>
          <div className="w-12 h-12 rounded-full shadow-md bg-mainGreen flexCenter shadow-gray-400">
            <span className="text-2xl font-bold text-white">!</span>
          </div>
          <div className="flex-col mx-12 text-center flexCenter gap-y-1">
            <p className="text-lg font-bold text-mainGreen">
              인앱 브라우저 호환성 문제로 인해 Safari로 접속해야합니다.
            </p>
            <p className="text-center text-subText">
              아래 버튼을 눌러 Safari를 실행시켜주세요.
              <br />
              {`Safari가 열리면, 주소창에 '붙여넣기 및 이동'을 눌러 InsectHub를 이용해주세요.`}
            </p>
          </div>
          <div className="absolute bottom-0 w-full flexCenter">
            <button
              className="w-full h-12 m-2 bg-mainGreen flexCenter rounded-10"
              onClick={openInDefaultBrowser}
            >
              <span className="font-semibold text-white">Safari로 열기</span>
            </button>
          </div>
        </div>
      </main>
    );
  return (
    <main>
      <div className="relative flex-col w-screen h-screen max-w-3xl mx-auto flexCenter gap-y-2">
        <div className="absolute top-4 left-2">
          <ICInsectHubLogo />
        </div>
        <div className="w-12 h-12 rounded-full shadow-md bg-mainGreen flexCenter shadow-gray-400">
          <span className="text-2xl font-bold text-white">!</span>
        </div>
        <div className="flex-col mx-12 text-center flexCenter gap-y-1">
          <p className="text-lg font-bold text-mainGreen">
            기본 브라우저가 아닙니다.
          </p>
          <p className="text-center text-subText">
            더 나은 서비스를 위해,
            <br />
            기본 브라우저에서 계속하세요.
          </p>
        </div>
        <div className="absolute bottom-0 w-full flexCenter">
          <button
            className="w-full h-12 m-2 bg-mainGreen flexCenter rounded-10"
            onClick={openInDefaultBrowser}
          >
            <span className="font-semibold text-white">
              기본 브라우저로 열기
            </span>
          </button>
        </div>
      </div>
    </main>
  );
}

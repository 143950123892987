import { DefaultResponse } from 'api';
import customAxios from 'api/customAxios';
import { Access, Auth, Language } from 'types/types';
import { masterAuthUrl } from '.';

export type MasterUser = {
  id: number;
  user: string;
  selected_id: number;
  email: string;
  auth: Auth;
  language: Language;
  access: Access;
  description: string;
};

export const getUsers = async ({ access }: { access: Access }) => {
  try {
    const response = await customAxios.post<
      { user?: MasterUser[] } & DefaultResponse
    >(`${masterAuthUrl(access)}/getUser.php`);

    if (response.data.isSuccess === false) {
      throw new Error(response.data.message);
    }
    return response.data.user || [];
  } catch (error) {
    console.error('Request Master Auth User error', error);
    throw error;
  }
};

export const getUser = async ({
  access,
  id,
}: {
  access: Access;
  id: number;
}) => {
  try {
    const requestBody = new FormData();
    requestBody.append('id', `${id}`);
    const response = await customAxios.post<
      { user?: MasterUser[] } & DefaultResponse
    >(`${masterAuthUrl(access)}/getUser.php`, requestBody);

    if (response.data.isSuccess === false) {
      throw new Error(response.data.message);
    }

    return response.data.user ? response.data.user[0] : null;
  } catch (error) {
    console.error('Request Master Auth User error', error);
    throw error;
  }
};

import { Access, Auth, Language } from 'types/types';
import { create } from 'zustand';

export interface UserInfoState {
  userName: string;
  auth: Auth;
  language: Language;
  farmNumber: number;
  access: Access;
  setUserInfo: (
    userInfo: Partial<
      Pick<
        UserInfoState,
        'userName' | 'auth' | 'language' | 'farmNumber' | 'access' // UserInfoState의 속성을 정의합니다.
      >
    >,
  ) => void; // setUserInfo 함수를 정의합니다.
}

//zustand
const useUserEditInfoStore = create<UserInfoState>((set) => ({
  userName: '',
  auth: 0,
  language: 1,
  farmNumber: 0,
  access: 0,
  setUserInfo: (userInfo) => set(userInfo),
}));

export default useUserEditInfoStore;

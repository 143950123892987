import { ReactComponent as RackType } from 'assets/img/racktype.svg';
import { ReactComponent as RailType } from 'assets/img/railType.svg';
import { ReactComponent as CrickbotType } from 'assets/img/crickbotType.svg';
import useFarmTypeStore from 'store/master/farmTypeStore';

type Props = {
  onDismiss: () => void;
};

export default function FarmTypeAddContents({ onDismiss }: Props) {
  const {
    setVisibility,
    setIsAdd,
    isExistCrickbot,
    isExistRackType,
    isExistRailType,
  } = useFarmTypeStore();
  const onClick = (
    rackType?: boolean,
    railType?: boolean,
    crickbot?: boolean,
  ) => {
    setVisibility({ rackType, railType, crickbot });
    setIsAdd({ rackType, railType, crickbot });
    onDismiss();
  };

  return (
    <div className="flex flex-col p-5 pt-0 gap-y-30px">
      <h2 className="text-lg font-bold">사육장치 추가</h2>
      <div className="flex flex-col gap-y-5">
        <button
          disabled={isExistRackType}
          className="disabled:opacity-30"
          onClick={() => onClick(true, undefined, undefined)}
        >
          <div className="flex items-center text-left gap-x-15px">
            <RackType />
            <div className="flex flex-col gap-y-5px">
              <h3 className="text-lg font-bold">선반형 사육대</h3>
              <p className="text-xs text-subText">
                선방형으로 다단식으로 사육합니다.
              </p>
            </div>
          </div>
        </button>
        <hr />
        <button
          disabled={isExistRailType}
          className="disabled:opacity-30"
          onClick={() => onClick(undefined, true, undefined)}
        >
          <div className="flex items-center text-left gap-x-15px">
            <RailType />
            <div className="flex flex-col gap-y-5px">
              <h3 className="text-lg font-bold">레일형 사육장치</h3>
              <p className="text-xs text-subText">
                사육상자를 다단으로 쌓아서 이송장치로 사육합니다.
              </p>
            </div>
          </div>
        </button>
        <hr />
        <button
          disabled={isExistCrickbot}
          className="disabled:opacity-30"
          onClick={() => onClick(undefined, undefined, true)}
        >
          <div className="flex items-center text-left gap-x-15px">
            <CrickbotType />
            <div className="flex flex-col gap-y-5px">
              <h3 className="text-lg font-bold">자동화 사육장치</h3>
              <p className="text-xs text-subText">
                사육로봇이 급여/급수/유산균 공급을 담당합니다.
              </p>
            </div>
          </div>
        </button>
      </div>
    </div>
  );
}

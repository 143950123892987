import { BottomSheet } from 'react-spring-bottom-sheet';
import FarmTypeAddContents from './FarmTypeAddContents';
import 'css/customSpringBottomSheet.css';

type Props = {
  open: boolean;
  onDismiss: () => void;
};

export default function FarmTypeAddBottomSheet({ open, onDismiss }: Props) {
  return (
    <BottomSheet open={open} onDismiss={onDismiss} className="">
      <FarmTypeAddContents onDismiss={onDismiss} />
    </BottomSheet>
  );
}

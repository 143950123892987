import { MasterUser } from 'api/master/getUser';
import { useAllFarmListQuery } from 'hooks/reactQuery/master/allFarmListQuery';

type Props = {
  user: MasterUser;
};

export default function UserEditOrigin({ user }: Props) {
  const { data: farms } = useAllFarmListQuery();
  const farm = farms?.find((farm) => farm.id === Number(user?.selected_id));

  return (
    <div className="grid grid-cols-2 gap-y-3">
      <div id="origin-farm-id" className="flex gap-x-2.5 items-center">
        <label className={labelStyle}>기존 농장</label>
        <span className={labelStyle}>
          {user.selected_id} ({farm?.farm_name})
        </span>
      </div>
      <div id="user-email" className="flex gap-x-2.5 items-center">
        <label className={labelStyle}>이메일</label>
        <span className={labelStyle}>{user.email}</span>
      </div>
      <div id="access-number" className="flex gap-x-2.5 items-center">
        <label className={labelStyle}>서버번호</label>
        <span className={labelStyle}>{user.access}</span>
      </div>
    </div>
  );
}
//style
const labelStyle = 'text-sm text-subText min-w-[80px]';
const inputStyle = 'w-full h-8 border border-subLine rounded-md px-2';

import clsx from 'clsx';
import Toast from 'components/common/notifications/Toast';
import { WARN_RED } from 'constants/color';
import { useFarmEdit } from 'hooks/reactQuery/master/useFarmEdit';
import { useNavigate } from 'react-router-dom';

export default function FarmEditButtons() {
  const { handleDeleteFarm, isEditButtonActive, onSubmit } = useFarmEdit();
  const navigate = useNavigate();

  const handleDeleteClick = async () => {
    const reseult = await Toast.fire({
      titleText: '정말 삭제하시겠습니까?',
      icon: 'question',
      showCancelButton: true,
      showConfirmButton: true,
      confirmButtonText: '네',
      cancelButtonText: '아니오',
      confirmButtonColor: WARN_RED,
      position: 'center',
      timerProgressBar: false,
      timer: 10000,
    });
    if (reseult.isConfirmed) {
      await handleDeleteFarm();
      navigate('..');
    }
  };
  return (
    <div className="flex gap-x-2.5 items-center">
      <button className={deleteStyle} onClick={handleDeleteClick}>
        삭제하기
      </button>
      <button
        disabled={!isEditButtonActive}
        className={editStyle}
        onClick={onSubmit}
      >
        수정하기
      </button>
    </div>
  );
}

// style
const deleteStyle = clsx(
  'rounded-[4px] border-warnRed border px-5 py-2.5 ',
  'text-warnRed font-medium',
);
const editStyle = clsx(
  'rounded-[4px] disabled:bg-subLine  px-5 py-2.5 flex-1',
  'font-medium',
  'text-white bg-mainGreen',
);

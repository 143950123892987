import { useParams } from 'react-router-dom';
import { useAllFarmListQuery, useDeleteFarmMutation } from './allFarmListQuery';
import useFarmTypeStore from 'store/master/farmTypeStore';
import { useInsertRailTypeMutation } from './railTypeQuery';
import { useInsertTwinstarFarmMutation } from './twinstarFarmQuery';
import useFarmEditInfoStore from 'store/master/farmEditInfoStore';
import { useUpdateFarmMutation } from '../farmQuery';
import Toast from 'components/common/notifications/Toast';

export const useFarmEdit = () => {
  const { farmId } = useParams();
  const farmNumber = Number(farmId);

  const { data: farms } = useAllFarmListQuery(); //농장 전체 목록
  const originFarm = farms?.find((farm) => farm.id === farmNumber); //originFarm: DB에 저장되어있는 농장 정보

  const { mutateAsync: deleteFarm } = useDeleteFarmMutation();
  const handleDeleteFarm = async () => {
    if (isNaN(farmNumber)) return;

    await deleteFarm(farmNumber);
  };

  const { isAddRackType, isAddRailType, racktypeInfo, railtypeInfo } =
    useFarmTypeStore();

  //농장 정보 상태
  const { name, address, access, country, isCountryChanged } =
    useFarmEditInfoStore();
  // 기존 정보와 달라진 정보가 있는지 확인
  const isFarmInfoChanged =
    name !== originFarm?.farm_name ||
    address !== originFarm?.farm_address ||
    isCountryChanged;

  const { mutateAsync: insertTwinstarfarm } = useInsertTwinstarFarmMutation({
    targetFarmNumber: farmNumber,
  });
  const { mutateAsync: insertRailType } = useInsertRailTypeMutation({
    targetFarmNumber: farmNumber,
  });
  const { mutateAsync: updateFarm } = useUpdateFarmMutation();
  //버튼 활성화 여부
  const isEditButtonActive =
    isFarmInfoChanged || isAddRackType || isAddRailType;

  const onSubmit = async () => {
    if (isNaN(farmNumber)) return;

    try {
      //농장 정보 수정
      if (isFarmInfoChanged) {
        await updateFarm({
          payload: {
            email: '',
            farmNumber,
            farmName: name,
            farmAddress: address,
            country: country,
          },
          access: access || 0,
        });
      }
      //농장 Rack 타입 추가
      if (isAddRackType) {
        await insertTwinstarfarm({
          lineCount: racktypeInfo.lineCount,
          lineBlock: racktypeInfo.blockCount,
          cellType: racktypeInfo.InsectType,
        });
      }
      //농장 Rail 타입 추가
      if (isAddRailType) {
        await insertRailType({
          lineCount: railtypeInfo.lineCount,
          blockCount: railtypeInfo.blockCount,
          boxCount: railtypeInfo.boxCount,
        });
      }
      Toast.fire({
        icon: 'success',
        title: '농장 정보가 수정되었습니다.',
      });
    } catch (error) {
      console.error('onSubmit error', error);
      Toast.fire({
        icon: 'error',
        title: '농장 정보 수정에 실패했습니다.',
      });
    }
  };
  return { handleDeleteFarm, onSubmit, isEditButtonActive };
};

import { MasterUser } from 'api/master/getUser';
import { useUpdateUserMutation } from 'hooks/reactQuery/master/usersQuery';
import useUserEditInfoStore from 'store/master/userEditInfoStore';

type Props = {
  user: MasterUser;
  onClose: () => void;
};

export default function UserEditButtons({ user, onClose }: Props) {
  const { userName, auth, language, access, farmNumber } =
    useUserEditInfoStore();

  const { mutateAsync } = useUpdateUserMutation();
  const onSubmit = async () => {
    await mutateAsync({
      targetCurrentFarmNumber: user.selected_id,
      targetUserEmail: user.email,
      updatedAccess: access,
      updatedAuth: auth,
      updatedLanguage: language,
      updatedUserName: userName,
      updatedFarmNumber: farmNumber,
    });
    onClose();
  };
  return (
    <div className="flex justify-end h-10 gap-x-2">
      <button className={cancelButtonStyle} onClick={onClose}>
        취소
      </button>
      <button className={createButtonStyle} onClick={onSubmit}>
        수정
      </button>
    </div>
  );
}
const cancelButtonStyle = 'rounded-10 text-subText bg-subLine flex-[0.3]';
const createButtonStyle = 'rounded-10 text-white bg-mainGreen flex-[0.7]';

import { useQuery } from '@tanstack/react-query';
import { requestDeviceWarning } from 'api/alarm/deviceWarning';
import useFarmStore from 'store/farmStore';

export const DEVICE_WARNING = 'device_warning';

export const useDeviceWarningQuery = () => {
  const { currentFarmNumber: farmNumber, access } = useFarmStore();
  return useQuery({
    queryKey: [DEVICE_WARNING, farmNumber],
    queryFn: async () => {
      const response = await requestDeviceWarning({
        payload: { farmNumber },
        access,
      });
      return response;
    },
    staleTime: 5 * 60 * 1000, //5분
    refetchInterval: 5 * 60 * 1000, //5분
    cacheTime: 5 * 60 * 1000, //5분
  });
};

import { DefaultResponse } from 'api';
import customAxios from 'api/customAxios';
import { Access, CellType } from 'types/types';
import { masterAuthUrl } from '.';

type Payload = {
  farmNumber: number; // 농장 번호
  lineCount: number; // 라인 수
  lineBlock: number; //한 라인당 블록(사육대) 수
  cellType: CellType; //곤충 종류
  access: Access;
};

export const insertTwinstarFarm = async (payload: Payload) => {
  const requestBody = new FormData();
  requestBody.append('farm_number', payload.farmNumber.toString());
  requestBody.append('line_count', payload.lineCount.toString());
  requestBody.append('line_block', payload.lineBlock.toString());
  requestBody.append('cell_type', `${payload.cellType}`);

  try {
    const response = await customAxios.post<DefaultResponse>(
      `${masterAuthUrl(payload.access)}/insertTwinstarfarm.php`,
      requestBody,
    );
    if (response.data.isSuccess === false) {
      throw new Error(response.data.message);
    }
    return response.data;
  } catch (error) {
    console.error('Request Twinstar Auth Insert Twinstar Farm error', error);
    throw error;
  }
};

import FarmAddButton from '../FarmAdd/FarmAddButton';
import FarmTable from './FarmTable';

export default function FarmList() {
  return (
    <div className="flex flex-col gap-y-[18px] w-[800px]">
      <div className="flex items-center justify-between">
        <h1 className="text-lg font-bold">농장 리스트</h1>
        <FarmAddButton />
      </div>
      <FarmTable />
    </div>
  );
}

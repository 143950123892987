import { useQuery } from '@tanstack/react-query';
import { getRailStructure } from 'api/worm/railStructure';
import { Access } from 'types/types';

export const RAIL_STRUCTURE_KEY = 'RAIL_STRUCTURE';

//rail 타입 농장에서 농장 기기 및 구조 정보를 쿼리하는 커스텀 훅
export const useRailStructureQuery = (farmNumber: number, access: Access) => {
  return useQuery({
    queryKey: [RAIL_STRUCTURE_KEY, farmNumber],
    queryFn: async () =>
      await getRailStructure({ payload: { farmNumber }, access }),
  });
};

import { Auth } from 'types/types';
import {
  TYPE_CRICKBOT_MASK,
  TYPE_HUB_MASK,
  TYPE_NONE_MASK,
  TYPE_RACK_MASK,
  TYPE_RAIL_MASK,
} from 'utils/userType';

type SidebarItem = {
  id: number;
  label: string;
  auth: Auth;
  userTypeMask: number;
};
export const RackTypeLandingId = 0;
export const HubTypeLandingId = 10;
export const RailTypeLandingId = 1;
export const sidebarItems: SidebarItem[] = [
  {
    id: 0,
    label: 'sidebar_cell_status',
    auth: 1,
    userTypeMask: TYPE_RACK_MASK,
  },
  {
    id: 1,
    label: 'sidebar_rail_status',
    auth: 1,
    userTypeMask: TYPE_RAIL_MASK,
  },
  {
    id: 6,
    label: 'sidebar_cricket_robot',
    auth: 1,
    userTypeMask: TYPE_CRICKBOT_MASK,
  },
  {
    id: 2,
    label: 'sidebar_level_control',
    auth: 1,
    userTypeMask: TYPE_RACK_MASK,
  },
  // { id: 3, label: 'sidebar_Incubator', active: false, userTypeMask: TYPE_RACK_MASK },
  { id: 4, label: 'sidebar_harvest', auth: 1, userTypeMask: TYPE_RACK_MASK },
  { id: 10, label: 'sidebar_relay', auth: 1, userTypeMask: TYPE_HUB_MASK },
  { id: 11, label: 'sidebar_operation', auth: 1, userTypeMask: TYPE_HUB_MASK },
  {
    id: 20,
    label: 'sidebar_farming_diary',
    auth: 1,
    userTypeMask: TYPE_NONE_MASK,
  },
  { id: 21, label: 'sidebar_admin', auth: 2, userTypeMask: TYPE_NONE_MASK },
];

import { useParams } from 'react-router-dom';
import FarmEditHead from './FarmEditHead';
import FarmEditInfo from './FarmEditInfo';
import FarmEditFarmType from './FarmEditFarmType';
import FarmEditButtons from './FarmEditButtons';
import FarmEditControllers from './FarmEditControllers';
import AsyncBoundary from 'components/common/AsyncBoundary';
import useEditInit from 'hooks/master/useEditInit';
import { useAllFarmListQuery } from 'hooks/reactQuery/master/allFarmListQuery';

export default function FarmEdit() {
  const { farmId } = useParams();
  const { data: farms } = useAllFarmListQuery();
  const farm = farms?.find((farm) => farm.id === Number(farmId));
  const access = farm?.access;
  //전역상태에 초기화하는 훅
  useEditInit({ targetFarmNumber: Number(farmId), access: access || 0 });

  if (!farmId || Number.isNaN(Number(farmId)))
    return (
      <div className="flexCenter">
        <p className="text-sm font-medium text-subText">
          농장을 찾을 수 없습니다.
        </p>
      </div>
    );

  return (
    <div className="w-[600px] flex flex-col p-5 gap-y-5 shadow-main bg-white rounded-md">
      <AsyncBoundary>
        <FarmEditHead farmId={Number(farmId)} />
        <hr />
        <FarmEditInfo farmId={Number(farmId)} />
        <hr />
        <FarmEditControllers farmId={Number(farmId)} />
        <hr />
        <FarmEditFarmType farmId={Number(farmId)} />
        <FarmEditButtons />
      </AsyncBoundary>
    </div>
  );
}

import { useAllFarmListQuery } from 'hooks/reactQuery/master/allFarmListQuery';

type Props = {
  farmId: number;
};
export default function FarmEditHead({ farmId }: Props) {
  const { data: farms } = useAllFarmListQuery();
  const farm = farms?.find((farm) => farm.id === farmId);
  if (!farm)
    return <div className="text-sm text-subText">농장을 찾을 수 없습니다.</div>;

  return (
    <div className="flex flex-col gap-y-1">
      <h1 className="text-lg font-bold">{farm.farm_name}</h1>
      <p className="text-sm font-medium text-subText">생성일: 표시할 수 없음</p>
    </div>
  );
}

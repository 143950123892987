import { getApiBaseUrl } from 'api';
import customAxios from './customAxios';
import { Farm, Access } from 'types/types';

// API 기본 경로 생성 함수
const apiBaseUrl = (access: Access) => `${getApiBaseUrl(access)}/webpage`;

interface FarmPayload {
  farmNumber: number;
}
interface RequestFarmResponse {
  farm: Farm[];
}
/**농장 번호(id)를 통해 farm data를 요청 */
export const requestFarm = async ({
  payload,
  access = 0,
}: {
  payload: FarmPayload;
  access: Access;
}) => {
  const requestBody = new FormData();
  requestBody.append('id', payload.farmNumber.toString());
  try {
    const response = await customAxios.post<RequestFarmResponse>(
      `${apiBaseUrl(access)}/farm.php`,
      requestBody,
    );
    const farms = response.data.farm;
    return farms.length > 0 ? farms[0] : undefined;
  } catch (error) {
    console.error('RequestFarmData error', error);
    throw error;
  }
};

/**새로운 FarmData 업데이트 */
interface UpdateFarmPayload {
  email: string;
  farmNumber: number;
  farmName: string;
  farmAddress: string;
  country: string;
}
export const requestUpdateFarm = async ({
  payload,
  access,
}: {
  payload: UpdateFarmPayload;
  access: Access;
}) => {
  const { email, farmNumber, farmName, farmAddress, country } = payload;
  const requestBody = new FormData();
  requestBody.append('email', email);
  requestBody.append('farm_number', farmNumber.toString());
  requestBody.append('farm_name', farmName);
  requestBody.append('farm_address', farmAddress);
  requestBody.append('country', country);

  try {
    customAxios.post(`${apiBaseUrl(access)}/update_farm.php`, requestBody);
    return true;
  } catch (error) {
    console.error('InsertFarmData error', error);
    throw error;
  }
};

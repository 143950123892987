import clsx from 'clsx';
import { forwardRef } from 'react';

import AlarmList from './AlarmList';
import AsyncBoundary from 'components/common/AsyncBoundary';

const AlarmListContainer = forwardRef<HTMLDivElement>((props, ref) => {
  return (
    <div className={listContainer} ref={ref}>
      <AsyncBoundary>
        <AlarmList />
      </AsyncBoundary>
    </div>
  );
});
AlarmListContainer.displayName = 'AlarmListContainer';
export default AlarmListContainer;
// style

const tailCSS = clsx(
  'after:absolute after:bottom-full after:contents-[``] after:left-1/2 after:-translate-x-1/2',
  'after:border-8 after:border-transparent after:border-b-white',
);
const listContainer = clsx(
  'absolute z-50 top-[120%] left-1/2 -translate-x-1/2',
  'shadow-main rounded-20 bg-white p-15px',
  'max-h-[200px] overflow-scroll',
  tailCSS,
);

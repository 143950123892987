import clsx from 'clsx';
import { useState } from 'react';
import FarmTypeAddBottomSheet from './FarmTypeModal/FarmTypeAddBottomSheet';
import useFarmTypeStore from 'store/master/farmTypeStore';
import AddRackTypeForm from './AddRackTypeForm';
import AddRailTypeForm from './AddRailTypeForm';

type Props = {
  farmId: number;
};

export default function FarmEditFarmType({ farmId }: Props) {
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [selectedFarmType, setSelectedFarmType] = useState<string>('');
  const { visibleRackType, visibleRailType } = useFarmTypeStore();
  return (
    <div id="master-main-farm-edit-type" className="flex flex-col gap-y-3">
      <h2 className="text-lg font-bold">농장타입</h2>
      <div
        id="master-main-farmtype-buttons"
        className="flex gap-x-2.5 items-center"
      >
        {visibleRackType && (
          <button
            className={buttonStyle}
            onClick={() => setSelectedFarmType('rack')}
          >
            선반형 사육장치
          </button>
        )}
        {visibleRailType && (
          <button
            className={buttonStyle}
            onClick={() => setSelectedFarmType('rail')}
          >
            레일형 사육대
          </button>
        )}

        <button className={buttonStyle} onClick={() => setModalOpen(true)}>
          +
        </button>
      </div>
      {selectedFarmType === 'rack' && <AddRackTypeForm />}
      {selectedFarmType === 'rail' && <AddRailTypeForm />}
      <FarmTypeAddBottomSheet
        open={modalOpen}
        onDismiss={() => setModalOpen(false)}
      />
    </div>
  );
}

// style
const buttonStyle = clsx(
  'px-4 py-2.5 rounded-full text-sm font-bold text-subText bg-subLine',
);

import { CELL_TYPES_MAP } from 'constants/cell';
import { CellType } from 'types/types';
import { create } from 'zustand';

interface FarmType {
  visibleRackType: boolean;
  visibleRailType: boolean;
  visibleCrickbot: boolean;
  changeableRackType: boolean; //사육대 타입 변경가능 여부
  changeableRailType: boolean; //레일타입 변경가능 여부
  changeableCrickbot: boolean; //크릭봇 변경가능 여부
  isExistRackType: boolean; //사육대 타입이 존재하는지 여부
  isExistRailType: boolean; //레일타입이 존재하는지 여부
  isExistCrickbot: boolean; //크릭봇이 존재하는지 여부
  isAddRackType: boolean; //사육대 타입 추가 여부
  isAddRailType: boolean; //레일타입 추가 여부
  isAddCrickbot: boolean; //크릭봇 추가 여
  racktypeInfo: {
    InsectType: CellType;
    lineCount: number;
    blockCount: number;
    floor: number;
  };
  railtypeInfo: {
    lineCount: number;
    blockCount: number;
    boxCount: number;
  };
  setVisibility: ({
    rackType,
    railType,
    crickbot,
  }: {
    rackType?: boolean;
    railType?: boolean;
    crickbot?: boolean;
  }) => void;
  setChangeable: ({
    rackType,
    railType,
    crickbot,
  }: {
    rackType?: boolean;
    railType?: boolean;
    crickbot?: boolean;
  }) => void;

  setIsExist: ({
    rackType,
    railType,
    crickbot,
  }: {
    rackType?: boolean;
    railType?: boolean;
    crickbot?: boolean;
  }) => void;

  setIsAdd: ({
    rackType,
    railType,
    crickbot,
  }: {
    rackType?: boolean;
    railType?: boolean;
    crickbot?: boolean;
  }) => void;

  setRacktypeInfo: (racktypeInfo: FarmType['racktypeInfo']) => void;
  setRailtypeInfo: (railtypeInfo: FarmType['railtypeInfo']) => void;
  init: () => void;
}

const useFarmTypeStore = create<FarmType>((set) => ({
  visibleRackType: false,
  visibleRailType: false,
  visibleCrickbot: false,
  changeableRackType: true,
  changeableRailType: true,
  changeableCrickbot: true,
  isExistRackType: false,
  isExistRailType: false,
  isExistCrickbot: false,
  isAddRackType: false,
  isAddRailType: false,
  isAddCrickbot: false,
  racktypeInfo: {
    InsectType: CELL_TYPES_MAP['cricket'],
    lineCount: 1,
    blockCount: 1,
    floor: 3,
  },
  railtypeInfo: {
    lineCount: 2,
    blockCount: 22,
    boxCount: 6,
  },
  setVisibility: ({ rackType, railType, crickbot }) =>
    set((state) => ({
      visibleRackType: rackType ?? state.visibleRackType,
      visibleRailType: railType ?? state.visibleRailType,
      visibleCrickbot: crickbot ?? state.visibleCrickbot,
    })),
  setChangeable: ({ rackType, railType, crickbot }) =>
    set((state) => ({
      changeableRackType: rackType ?? state.changeableRackType,
      changeableRailType: railType ?? state.changeableRailType,
      changeableCrickbot: crickbot ?? state.changeableCrickbot,
    })),
  setIsExist: ({ rackType, railType, crickbot }) =>
    set((state) => ({
      isExistRackType: rackType ?? state.isExistRackType,
      isExistRailType: railType ?? state.isExistRailType,
      isExistCrickbot: crickbot ?? state.isExistCrickbot,
    })),
  setIsAdd: ({ rackType, railType, crickbot }) =>
    set((state) => ({
      isAddRackType: rackType ?? state.isAddRackType,
      isAddRailType: railType ?? state.isAddRailType,
      isAddCrickbot: crickbot ?? state.isAddCrickbot,
    })),
  setRacktypeInfo: (racktypeInfo) => set({ racktypeInfo }),
  setRailtypeInfo: (railtypeInfo) => set({ railtypeInfo }),
  init: () =>
    set({
      visibleRackType: false,
      visibleRailType: false,
      visibleCrickbot: false,
      changeableRackType: true,
      changeableRailType: true,
      changeableCrickbot: true,
      isExistRackType: false,
      isExistRailType: false,
      isExistCrickbot: false,
      isAddRackType: false,
      isAddRailType: false,
      isAddCrickbot: false,
      racktypeInfo: {
        InsectType: CELL_TYPES_MAP['cricket'],
        lineCount: 1,
        blockCount: 1,
        floor: 3,
      },
      railtypeInfo: {
        lineCount: 2,
        blockCount: 22,
        boxCount: 6,
      },
    }),
}));

export default useFarmTypeStore;

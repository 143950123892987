import customAxios from 'api/customAxios';
import { Access } from 'types/types';
import { RailStructure } from 'types/worm';
import { wormApiUrl } from '.';

interface GetRailStructurePayload {
  farmNumber: number;
}

//rail 타입 농장에서 농장 기기 및 구조 정보를 받아오는 함수
export const getRailStructure = async ({
  payload,
  access = 0,
}: {
  payload: GetRailStructurePayload;
  access: Access;
}) => {
  const requestBody = new FormData();
  requestBody.append('farmNumber', payload.farmNumber.toString());
  try {
    const response = await customAxios.post<{
      railStructure?: RailStructure[];
      isSuccess: boolean;
      code: number;
      message: string;
    }>(`${wormApiUrl(access)}/getRailStructure.php`, requestBody);
    const railStructures = response.data.railStructure;
    return railStructures ? railStructures[0] : null;
  } catch (error) {
    console.error('Request Rail Structure error', error);
    throw error;
  }
};

import { useState } from 'react';
import UserAddForm from './UserAddForm';
import Modal from 'components/common/Modal';

export default function UserAddButton() {
  const [openModal, setOpenModal] = useState(false);
  const onClick = () => {
    setOpenModal(true);
  };
  const onClose = () => setOpenModal(false);
  return (
    <>
      <div className="flex justify-center" onClick={onClick}>
        <button className="text-sm font-bold text-mainGreen">
          회원 추가 +
        </button>
      </div>
      <Modal open={openModal} onClose={onClose}>
        <UserAddForm onClose={onClose} />
      </Modal>
    </>
  );
}

import { useAllUserListQuery } from 'hooks/reactQuery/master/usersQuery';
import useUserListPageStore from 'store/master/userListPageStore';
import UserItem from './UserItem';

export default function UserItems() {
  const { data: farms } = useAllUserListQuery();
  const { currentPage, userPerPage } = useUserListPageStore();

  if (!farms) return <div className="bg-white lg:h-[612px]" />;

  const start = (currentPage - 1) * userPerPage;
  const end = currentPage * userPerPage;

  const slicedUsers = farms.slice(start, end); //
  return (
    <div className="flex flex-col divide-y">
      {slicedUsers.map((user, index) => (
        <UserItem key={index} user={user} />
      ))}
    </div>
  );
}

import customAxios from 'api/customAxios';
import { Access } from 'types/types';
import { masterAuthUrl } from '.';
import { DefaultResponse } from 'api';

type Payload = {
  farnNumber: number;
};
type Params = {
  payload: Payload;
  access: Access;
};
export const deleteFarm = async (params: Params) => {
  const { payload, access } = params;
  const requestBody = new FormData();
  requestBody.append('farm_number', String(payload.farnNumber));
  try {
    const response = await customAxios.post<DefaultResponse>(
      `${masterAuthUrl(access)}/deleteFarm.php`,
      requestBody,
    );
    if (response.data.isSuccess === false) {
      throw new Error(response.data.message);
    }
    return response.data;
  } catch (error) {
    console.error('Request Master Auth Delete Farm error', error);
    throw error;
  }
};

import { useAllFarmListQuery } from 'hooks/reactQuery/master/allFarmListQuery';
import AddControllerButton from './AddControllerButton';
import { useFarmControllerQuery } from 'hooks/reactQuery/master/farmControllerQuery';
import FarmEditRelay from './FarmEditRelay';

type Props = {
  farmId: number;
};
export default function FarmEditControllers({ farmId }: Props) {
  //농장 기본정보 가져오기 - farms에서 선택한 농장의 정보를 가져온다.
  const { data: farms } = useAllFarmListQuery();
  const farm = farms?.find((farm) => farm.id === farmId);
  const access = farm?.access || 0;

  //해당 농장에서 보유한 컨트롤러 목록 출력
  const { data: controllers } = useFarmControllerQuery(farmId);
  return (
    <div className="flex flex-col gap-y-3">
      {controllers && controllers.length > 0 ? (
        controllers.map((controller) => (
          <FarmEditRelay
            key={`controller-${controller}`}
            farmId={farmId}
            controllerNumber={controller}
            access={access}
          />
        ))
      ) : (
        <h2 className="text-lg font-bold">컨트롤러가 없습니다.</h2>
      )}
      <AddControllerButton farmId={farmId} />
    </div>
  );
}

import clsx from 'clsx';
import { useAllFarmListQuery } from 'hooks/reactQuery/master/allFarmListQuery';
import useFarmListPageStore from 'store/master/farmListPageStore';

export default function FarmPagination() {
  const { currentPage, farmPerPage, setCurrentPage } = useFarmListPageStore();
  const { data: farms } = useAllFarmListQuery();
  if (!farms) return <div></div>;
  const maxPage = Math.ceil(farms.length / farmPerPage);
  const pageNumbers = Array.from({ length: maxPage }, (_, i) => i + 1);
  return (
    <div className="flex justify-center gap-x-2">
      {pageNumbers.map((number) => (
        <button
          key={number}
          className={clsx(
            'select-none text-sm text-subText rounded-full flexCenter w-[23px] h-[23px] text-center',
            {
              'bg-mainGreen text-white font-bold': number === currentPage,
            },
          )}
          onClick={() => setCurrentPage(number)}
        >
          {number}
        </button>
      ))}
    </div>
  );
}

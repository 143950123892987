import { headerContainerClass, logoClass } from 'components/header/Header';
import nav_logo from 'assets/common/nav/nav_logo.png';
import { useEffect, useState } from 'react';
import Button from 'components/common/buttons/Button';
import useTranslate from 'hooks/useTranslate';
import IcLogo from 'components/icon/IcLogo';

function Initializing() {
  const t = useTranslate();
  const [isVisible, setIsVisible] = useState(false);
  useEffect(() => {
    const timer = setTimeout(() => {
      setIsVisible(true);
    }, 10 * 1000); // 10초 후에도 로딩 중일 경우 새로고침 버튼 추가

    return () => clearTimeout(timer);
  }, []);
  return (
    <div className="flex items-center justify-center w-screen h-screen">
      <div className={headerContainerClass}>
        <div className="flex items-center lg:px-[20px] py-[15px]">
          <div className={logoClass}>
            <img src={nav_logo} alt={t('반달팜')} />
          </div>
        </div>
      </div>
      <div className="relative flex flex-col items-center">
        <IcLogo width={90} height={90} />
        <div className="absolute inset-y-0 left-0 bg-mainGreen mix-blend-lighten animate-loading "></div>
        <div className={`relative top-5 ${!isVisible && 'invisible'}`}>
          <Button
            onClick={() => {
              window.location.reload();
            }}
          >
            {t('새로고침')}
          </Button>
        </div>
      </div>
    </div>
  );
}

export default Initializing;

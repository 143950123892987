import { RELAY_TEXT } from 'setting/relay';
import { isRelayType, RelayType } from 'types/types';
import Select, { StylesConfig } from 'react-select';
import { OptionType } from 'utils/select';
import { MAIN_GREEN, SUB_LINE } from 'constants/color';
import useTranslate from 'hooks/useTranslate';

type Props = {
  selectedRelay?: RelayType;
  onSelect: (value: RelayType) => void;
};

export default function RelaySelect({ selectedRelay, onSelect }: Props) {
  const t = useTranslate();
  const relayTypes: RelayType[] = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9];
  const options: OptionType[] = relayTypes.map((number) => ({
    value: `${number}`,
    label: t(RELAY_TEXT[number]),
  }));

  return (
    <Select
      className="flex flex-1 h-full"
      styles={relaySelectStyle}
      options={options}
      value={options.find(
        (option) =>
          selectedRelay !== undefined && option.value === `${selectedRelay}`,
      )}
      onChange={async (selectedOption) => {
        if (!selectedOption) return;
        const selectedRelay = Number(selectedOption.value);

        isRelayType(selectedRelay) && (await onSelect(selectedRelay));
      }}
    />
  );
}

// react select style
export const relaySelectStyle: StylesConfig<OptionType, false> = {
  option: (provided) => ({
    ...provided,
    opacity: 0.8,
    // backgroundColor: state.isFocused ? colorMainGreen : undefined,
  }),
  control: (provided, state) => ({
    ...provided,
    flex: 1,
    borderColor: SUB_LINE,
    outlineColor: state.isFocused ? MAIN_GREEN : undefined,
    boxShadow: 'none',
    borderRadius: 6,
    height: 45,
    padding: '0 10px',
    fontSize: 14,
    fontWeight: 700,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    ':hover': {
      outlineColor: SUB_LINE,
      borderColor: SUB_LINE,
    },
    ':focus': {
      borderColor: SUB_LINE,
      outlineColor: SUB_LINE,
    },
  }),
  singleValue: (provided) => ({
    ...provided,
  }),
  valueContainer: (provided) => ({
    ...provided,
    padding: 0,
  }),
  placeholder: (provided) => ({
    ...provided,
    color: '#7D7B7B',
    fontSize: 14,
    fontWeight: 400,
  }),
  indicatorSeparator: (provided) => ({ ...provided, display: 'none' }),
};

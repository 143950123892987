import { MasterUser } from 'api/master/getUser';
import { create } from 'zustand';

// 마스터 페이지에서 수정하기 위해 선택한 유저 정보를 저장하는 스토어
interface SelectedUserStore {
  selectedUser: MasterUser | null;
  setSelectedUser: (user: MasterUser | null) => void;
}

const useSelectedUserStore = create<SelectedUserStore>((set) => ({
  selectedUser: null,
  setSelectedUser: (user: MasterUser | null) => set({ selectedUser: user }),
}));

export default useSelectedUserStore;

import clsx from 'clsx';
import MasterHeader from 'components/master/MasterHeader';
import { Route, Routes } from 'react-router-dom';
import UserListPage from './user/UserListPage';

export default function MasterUserPage() {
  return (
    <div className={clsx('relative text-mainText')}>
      <MasterHeader />
      {/* 화면의 가운데 위치 */}
      <div
        id="master-container"
        className="h-screen overflow-y-auto pt-headerHeight"
      >
        <Routes>
          <Route path="/" element={<UserListPage />} />
        </Routes>
      </div>
    </div>
  );
}

import clsx from 'clsx';
import { useAllFarmListQuery } from 'hooks/reactQuery/master/allFarmListQuery';
import useFarmEditInfoStore from 'store/master/farmEditInfoStore';

type Props = {
  farmId: number;
};

export default function FarmEditInfo({ farmId }: Props) {
  const { data: farms } = useAllFarmListQuery();

  const farm = farms?.find((farm) => farm.id === farmId);
  const {
    id,
    name,
    address,
    access,
    country,
    setFarmInfo,
    setIsCountryChanged,
  } = useFarmEditInfoStore();

  if (!farm)
    return <div className="text-sm text-subText">농장을 찾을 수 없습니다.</div>;
  return (
    <form id="master-main-farm-edit-info" className="flex flex-col gap-y-3 ">
      <h2 className="text-lg font-bold">농장정보</h2>
      <div className="grid grid-cols-2 gap-x-30px gap-y-1">
        <div className="flex gap-x-0.5 items-center">
          <span className={labelStyle}>번호</span>
          <input className={inputStyle} disabled value={id} />
        </div>
        <div className="flex gap-x-0.5 items-center">
          <span className={labelStyle}>국가</span>
          <input
            className={inputStyle}
            value={country}
            onChange={(e) => {
              setIsCountryChanged(true);
              setFarmInfo({ country: e.target.value });
            }}
          />
        </div>
        <div className="flex gap-x-0.5 items-center">
          <span className={labelStyle}>농장명</span>
          <input
            className={inputStyle}
            value={name}
            onChange={(e) => {
              setFarmInfo({ name: e.target.value });
            }}
          />
        </div>
        <div className="flex gap-x-0.5 items-center">
          <span className={labelStyle}>서버번호</span>
          <input className={inputStyle} value={access} disabled />
        </div>
        <div className="flex gap-x-0.5 items-center col-span-2">
          <span className={labelStyle}>농장주소</span>
          <input
            className={inputStyle}
            value={address}
            onChange={(e) => {
              setFarmInfo({ address: e.target.value });
            }}
          />
        </div>
      </div>
    </form>
  );
}

// style
const labelStyle = clsx('text-sm text-subText min-w-[70px] whitespace-nowrap');
const inputStyle = clsx(
  'border-subLine border rounded-md px-2.5 py-3 flex-1',
  'focus:outline-none',
  'font-bold text-sm',
);

import { create } from 'zustand';

interface UserListPageState {
  currentPage: number;
  setCurrentPage: (newPage: number) => void;
  userPerPage: number;
  setUserPerPage: (newUserPerPage: number) => void;
}

const useUserListPageStore = create<UserListPageState>((set) => ({
  currentPage: 1,
  setCurrentPage: (newPage: number) => set({ currentPage: newPage }),
  userPerPage: 10,
  setUserPerPage: (newUserPerPage: number) =>
    set({ userPerPage: newUserPerPage }),
}));

export default useUserListPageStore;

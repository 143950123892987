import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import {
  requestBatchUpdateControllers,
  requestController,
  requestUpdateController,
} from 'api/controller/controller';
import useControllerStore from 'store/controllerStore';
import useFarmStore from 'store/farmStore';

export const CONTROLLER_QUERY_KEY = 'controller';

// 컨트롤러 데이터를 쿼리하는 커스텀 훅
export const useControllerQuery = (
  farmNumber: number,
  controllerNumber: number,
  access: number,
) => {
  return useQuery({
    queryKey: [CONTROLLER_QUERY_KEY, farmNumber, controllerNumber], // 컨트롤러 데이터와 id를 기반으로 한 고유한 쿼리 키
    queryFn: async () =>
      await requestController({
        payload: { farmNumber, controllerNumber },
        access,
      }),
    staleTime: 30 * 1000, // 30초
    refetchInterval: 30 * 1000,
    cacheTime: 3 * 60 * 1000, // 3분
  });
};

//현재 농장에 보유한 모든 컨트롤러 정보를 여러개 가져올 때 사용하는 커스텀 훅
export const useMultiControllerQuery = () => {
  const { currentFarmNumber: farmNumber, access } = useFarmStore();
  const { controllerListMap } = useControllerStore();
  const currentControllerList = controllerListMap[farmNumber];
  return useQuery({
    queryKey: [CONTROLLER_QUERY_KEY, farmNumber],
    queryFn: async () => {
      // currentControllerList에 있는 여러개의 컨트롤러 정보를 가져옴
      const controllerListResponseList = await Promise.all(
        currentControllerList.map((controllerNumber) =>
          requestController({
            payload: { farmNumber, controllerNumber },
            access,
          }),
        ),
      );
      return controllerListResponseList.map((response) => response.controller);
    },
    staleTime: 30 * 1000,
    refetchInterval: 30 * 1000,
    cacheTime: 3 * 60 * 1000,
  });
};

export const useUpdateControllerMutation = (
  farmNumber: number,
  controllerNumber: number,
) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: requestUpdateController,
    onSuccess: () => {
      queryClient.invalidateQueries([
        CONTROLLER_QUERY_KEY,
        farmNumber,
        controllerNumber,
      ]);
    },
  });
};

export const useBatchUpdateControllersMutation = (
  farmNumber: number,
  controllerNumber: number,
) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: requestBatchUpdateControllers,
    onSuccess: () => {
      queryClient.invalidateQueries([
        CONTROLLER_QUERY_KEY,
        farmNumber,
        controllerNumber,
      ]);
    },
  });
};

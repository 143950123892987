import FarmEdit from 'components/master/FarmEdit/FarmEdit';

export default function FarmEditPage() {
  //url에서 farmId를 가져오는 코드

  return (
    <div id="master-main-farm-edit" className="flex flex-col items-center pt-7">
      <FarmEdit />
    </div>
  );
}

import clsx from 'clsx';
import { useDeviceWarningQuery } from 'hooks/reactQuery/alarm/deviceWarningQuery';

export default function AlertCounter() {
  const { data } = useDeviceWarningQuery();
  if (!data) return null;
  const { cell, controller } = data.deviceWarning;
  const cellWaringCounter = Array.from(
    new Set(cell.map((cellWarning) => cellWarning.line_number)),
  );
  const alertCount = controller.length + cellWaringCounter.length;
  if (alertCount <= 0) return null;
  return <span className={counterStyle}>{alertCount}</span>;
}
// style
const counterStyle = clsx(
  'p-[3px] h-[14px] min-w-[14px] rounded-full text-white bg-warnRed',
  'flexCenter text-center',
  'absolute -right-2 -bottom-1',
  'text-[10px] leading-none',
);

import { DefaultResponse } from 'api';
import customAxios from 'api/customAxios';
import { Access } from 'types/types';
import { masterAuthUrl } from '.';

type Payload = {
  farmNumber: number; //farm_number
  access: Access;
};

type controllerNumberList = { controller_number: number }[];
/**
 * 농장에 연결된 컨트롤러 리스트를 가져옵니다.
 */
export const getControllers = async (payload: Payload) => {
  try {
    const requestBody = new FormData();
    requestBody.append('farm_number', payload.farmNumber.toString());
    const response = await customAxios.post<
      DefaultResponse & { controller?: controllerNumberList }
    >(`${masterAuthUrl(payload.access)}/getController.php`, requestBody);
    if (response.data.isSuccess === false) {
      throw new Error(response.data.message);
    }
    if (!response.data.controller) {
      return [];
    }
    return response.data.controller.map(
      (controller) => controller.controller_number,
    );
  } catch (error) {
    console.error('Request Master Auth Get Controller error', error);
    throw error;
  }
};

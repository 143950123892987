export const ACTION_TYPES = {
  TEMPERATURE_CHANGE: 'temperature_change',
  HUMIDITY_CHANGE: 'humidity_change',
  CO2_CHANGE: 'co2_change',
  TEMPERATURE_RANGE_CHANGE: 'temperature_range_change',
  HUMIDITY_RANGE_CHANGE: 'humidity_range_change',
  CO2_RANGE_CHANGE: 'co2_range_change',
  SPRAY_CHANGE: 'spray_change',
  VENT_CHANGE: 'vent_change',
  FEED_CHANGE: 'feed_change',
  LANGUAGE_CHANGE: 'language_change',
  SPRAY_TERM_CHANGE: 'water_spray_term_change',
  FEED_TERM_CHANGE: 'feed_term_change',
  VENT_TERM_CHANGE: 'vent_term_change',
  START_HOUR_CHANGE: 'start_hour_change',
  IS_AUTO: 'is_auto',
  LIGHT_CHANGE: 'light_change',
  NIGHT_TIME_CHANGE: 'night_time_change',
};

import React from 'react';
import ReactDOM from 'react-dom/client';
import App from 'App';

// css 기본 설정
import 'setting/font.css';
import 'setting/layout.css';
import 'css/index.css';
import 'css/slider.css';
import 'react-spring-bottom-sheet/dist/style.css';
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<App />);

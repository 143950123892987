import { Access } from 'types/types';

export function getApiBaseUrl(access = 0) {
  if (process.env.NODE_ENV === 'development') return ''; //로컬에서 실행 시,pakage json의 proxy 서버 이용
  if (process.env.REACT_APP_PRODUCT_MODE === 'TEST')
    return process.env.REACT_APP_TEST_SERVER_URL;
  if (access >= 0 && access <= 2) {
    return `${process.env.REACT_APP_API_PREFIX}${access}${process.env.REACT_APP_API_SUFFIX}`;
  } else {
    console.error('Invalid access value');
  }
}

export type DefaultResponse = {
  isSuccess: boolean;
  code: number;
  message: string;
};

// API 기본 경로 생성 함수
export const apiWebpageUrl = (access: Access) =>
  `${getApiBaseUrl(access)}/webpage`;

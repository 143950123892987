import { create } from 'zustand';

interface layoutState {
  isNavCollapsed: boolean;
  setIsNavCollapsed: (value: boolean) => void;
  toggleNavSize: () => void;
}

const useLayoutStore = create<layoutState>((set) => ({
  isNavCollapsed: true,
  setIsNavCollapsed: (value: boolean) => set({ isNavCollapsed: value }),
  toggleNavSize: () => {
    set((state) => ({ isNavCollapsed: !state.isNavCollapsed }));
  },
}));
export default useLayoutStore;

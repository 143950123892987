import { Locale } from 'types/types';

/**
 * 주어진 날짜와 로케일을 기반으로 주간 데이터 형식으로 변환합니다.
 * @param dateTime - 날짜 및 시간 문자열
 * @param locale - 로케일 설정 (1: 한국어, 2: 영어)
 * @returns 주간 데이터 형식의 문자열
 */
export const getWeekly = (dateTime: string, locale: number) => {
  if (locale === 1) {
    const startDay = new Date(dateTime);
    const year = startDay.getFullYear();
    const month = startDay.getMonth() + 1;
    const day = startDay.getDate();
    return year + '년 ' + month + '월 ' + day + '일 ';
  } else if (locale === 2) {
    const startDay = new Date(dateTime);
    const day = startDay.toLocaleString('en', { dateStyle: 'long' });
    return day;
  }
};

/**
 * 날짜 및 시간을 주어진 로케일에 맞는 형식으로 변환합니다.
 * @param dateTime - 날짜 및 시간 문자열
 * @param locale - 로케일 설정 (1: 한국어, 2: 영어)
 * @returns 형식화된 날짜 및 시간 문자열
 */
export const formatDateTime = (dateTime: string, locale: number) => {
  if (locale === 1) {
    const startDay = new Date(dateTime);
    const year = startDay.getFullYear();
    const month = startDay.getMonth() + 1;
    const day = startDay.getDate();
    let hours = startDay.getHours();
    const minutes = startDay.getMinutes();
    const ampm = hours >= 12 ? 'pm' : 'am';
    hours = hours % 12;
    hours = hours ? hours : 12;
    return `${year}년 ${month}월 ${day}일 ${hours
      .toString()
      .padStart(2, '0')}:${minutes.toString().padStart(2, '0')} ${ampm}`;
  }
  if (locale === 2) {
    const startDay = new Date(dateTime);
    const date = startDay.toLocaleString('en', { dateStyle: 'long' });
    let hours = startDay.getHours();
    const minutes = startDay.getMinutes();
    const ampm = hours >= 12 ? 'pm' : 'am';
    hours = hours % 12;
    hours = hours ? hours : 12;
    return `${date} ${hours.toString().padStart(2, '0')}:${minutes
      .toString()
      .padStart(2, '0')} ${ampm}`;
  }
};

/**
 * 날짜와 시간을 주어진 형식에 맞도록 변환합니다.
 * @param datetime - 날짜 및 시간 문자열
 * @returns 형식화된 날짜 및 시간 문자열
 */
export const dateData = (datetime: string) => {
  const data = datetime.match(/\d+/g);
  if (!data) return;
  const newData = new Date(
    Number(data[0]),
    Number(data[1]) - 1,
    Number(data[2]),
    Number(data[3]),
    Number(data[4]),
  );
  const date = newData.toLocaleString('en', { dateStyle: 'full' });
  let hours = newData.getHours();
  const minutes = newData.getMinutes();
  const ampm = hours >= 12 ? 'pm' : 'am';
  hours = hours % 12;
  hours = hours ? hours : 12;
  return `${date} ${hours.toString().padStart(2, '0')}:${minutes
    .toString()
    .padStart(2, '0')} ${ampm}`;
};

/**
 * 주어진 날짜와 현재 날짜 사이의 경과 일 수를 계산합니다.
 * @param dateString - 날짜 문자열
 * @returns 경과 일 수
 */
export const getDaysPassed = (dateString: string) => {
  const currentDate = new Date(); // 현재 날짜
  const targetDate = new Date(dateString); // 주어진 날짜

  // 현재 날짜와 주어진 날짜의 차이를 계산
  const timeDiff = currentDate.getTime() - targetDate.getTime();
  const daysDiff = Math.ceil(timeDiff / (1000 * 3600 * 24)); // 차이를 일(day) 단위로 계산

  return daysDiff;
};

/**
 * 주어진 날짜 문자열에 일 수를 더한 후의 날짜를 반환합니다.
 * @param dateString - 날짜 문자열 (예: '2023-01-01')
 * @param daysToAdd - 더할 일 수
 * @returns 일 수를 더한 후의 날짜 문자열
 */
export function addDaysToDate(dateString: string, daysToAdd: number): string {
  const date = new Date(dateString);
  date.setDate(date.getDate() + daysToAdd);
  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const day = date.getDate().toString().padStart(2, '0');
  return `${year}-${month}-${day}`;
}

/**
 * 현재 날짜를 'yyyy-mm-dd' 형식으로 반환합니다.
 * @param date - 선택적으로 지정할 날짜 객체
 * @returns 'yyyy-mm-dd' 형식의 날짜 문자열
 */
export const getDateString = (date?: Date) => {
  const today = date || new Date();
  const year = today.getFullYear();
  const month = String(today.getMonth() + 1).padStart(2, '0');
  const day = String(today.getDate()).padStart(2, '0');
  return `${year}-${month}-${day}`;
};

export function getWeekDates(baseDate: Date): Date[] {
  const dayOfWeek = baseDate.getDay(); // 0 (일요일)부터 6 (토요일)까지의 값

  // 주어진 날짜를 해당 주의 일요일로 설정
  const sunday = new Date(baseDate);
  sunday.setDate(baseDate.getDate() - dayOfWeek);

  const weekDates: Date[] = [];

  // 일요일부터 토요일까지의 날짜를 배열에 추가
  for (let i = 0; i < 7; i++) {
    const currentDate = new Date(sunday);
    currentDate.setDate(sunday.getDate() + i);
    weekDates.push(currentDate);
  }

  return weekDates;
}

export function getTwoWeeksDates(baseDate: Date): Date[] {
  // 1월 1일부터 오늘까지의 일수를 계산합니다.
  const startYear = new Date(baseDate.getFullYear(), 0, 1);
  const daysPassed = Math.ceil(
    (baseDate.valueOf() - startYear.valueOf()) / (24 * 60 * 60 * 1000),
  );

  // 현재 주차를 계산합니다.
  const currentWeek = Math.ceil(daysPassed / 7);

  // 일요일 날짜를 계산합니다.
  baseDate.setDate(baseDate.getDate() - baseDate.getDay());

  // 홀수 주차면 이전 주 일요일로 설정하고, 짝수 주차면 현재 주 일요일로 설정합니다.
  if (currentWeek % 2 === 1) {
    baseDate.setDate(baseDate.getDate() - 7);
  }

  const weekDates: Date[] = [];

  for (let i = 0; i < 14; i++) {
    const currentDate = new Date(baseDate);
    currentDate.setDate(baseDate.getDate() + i);
    weekDates.push(currentDate);
  }

  return weekDates;
}

/**
 * Check if the first date string is earlier than the second date string.
 * Both date strings are in "YYYY-MM-DD" format.
 *
 * @param dateStr1 - The first date string in "YYYY-MM-DD" format.
 * @param dateStr2 - The second date string in "YYYY-MM-DD" format.
 * @returns true if dateStr1 is earlier than or equal to dateStr2, false otherwise.
 */
export function isDate1Earlier(dateStr1: string, dateStr2: string): boolean {
  // Convert date strings to Date objects
  const date1 = new Date(dateStr1);
  const date2 = new Date(dateStr2);

  // Return true if date1 is earlier than or equal to date2
  return date1 < date2;
}

export const monthsAgo = (month: number) => {
  const date = new Date();
  date.setMonth(date.getMonth() - month);
  date.setHours(0, 0, 0, 0); // 시간, 분, 초, 밀리초를 0으로 설정 (해당 일의 시작 시간)
  return date;
};

export function getStartOfYear(date?: Date): Date {
  const today = date || new Date();
  return new Date(today.getFullYear(), 0, 1); // 0은 1월을 나타냅니다.
}

export function getStartOfMonth(date?: Date): Date {
  const today = date || new Date();
  return new Date(today.getFullYear(), today.getMonth(), 1);
}

export function getStartOfWeek(date?: Date): Date {
  const today = date ? new Date(date) : new Date();
  const day = today.getDay(); // 일요일: 0, 월요일: 1, ..., 토요일: 6
  return new Date(today.setDate(today.getDate() - day));
}

export function getLastWeek(date?: Date) {
  const today = date ? new Date(date) : new Date();

  const lastSunday = new Date(
    today.setDate(today.getDate() - today.getDay() - 7),
  );
  const lastSaturday = new Date(today.setDate(today.getDate() + 6));

  return {
    start: lastSunday,
    end: lastSaturday,
  };
}

export function getLastMonth() {
  const today = new Date();

  // Get the first day of this month
  const firstDayOfThisMonth = new Date(
    today.getFullYear(),
    today.getMonth(),
    1,
  );

  // Subtract one day from the first day of this month to get the last day of last month
  const lastDayOfLastMonth = new Date(
    firstDayOfThisMonth.setDate(firstDayOfThisMonth.getDate() - 1),
  );

  // Get the first day of last month
  const firstDayOfLastMonth = new Date(
    lastDayOfLastMonth.getFullYear(),
    lastDayOfLastMonth.getMonth(),
    1,
  );

  return {
    start: firstDayOfLastMonth,
    end: lastDayOfLastMonth,
  };
}

export function getLastYear() {
  const today = new Date();

  // Get the first day of this year
  const firstDayOfThisYear = new Date(today.getFullYear(), 0, 1);

  // Subtract one day from the first day of this year to get the last day of last year
  const lastDayOfLastYear = new Date(
    firstDayOfThisYear.setDate(firstDayOfThisYear.getDate() - 1),
  );

  // Get the first day of last year
  const firstDayOfLastYear = new Date(lastDayOfLastYear.getFullYear(), 0, 1);

  return {
    start: firstDayOfLastYear,
    end: lastDayOfLastYear,
  };
}

export function convertHyphensToDotsInDate(dateString: string) {
  return dateString.replace(/-/g, '.').substring(2);
}

// 기준일이 속한 첫째날(일요일)과 마지막날(토요일) Date를 반환
export const getWeekStartEnd = (d: Date) => {
  const weekStart = new Date(d);
  weekStart.setDate(d.getDate() - d.getDay());
  const weekEnd = new Date(weekStart);
  weekEnd.setDate(weekStart.getDate() + 6);
  return { start: weekStart, end: weekEnd };
};

// 주어진 Date 객체가 속한 해의 1월 1일과 12월 31일 Date 객체를 반환
export const getYearStartEnd = (d: Date) => {
  const yearStart = new Date(d.getFullYear(), 0, 1); // 0은 1월을 의미합니다.
  const yearEnd = new Date(d.getFullYear(), 11, 31); // 11은 12월을 의미합니다.
  return { start: yearStart, end: yearEnd };
};

/**
 * Splits and formats a date-time string into separate date and time strings.
 *
 * @param {string} dateTimeStr - A string in the format "YYYY-MM-DD HH:MM:SS"
 * @returns {Array<string>} - An array containing the formatted date and time strings.
 * */
export const splitAndFormatDateTime = (dateTimeStr: string) => {
  // Split date and time
  const [date, time] = dateTimeStr.split(' ');

  // Format date
  const formattedDate = date.split('-').join('.');

  // Extract hours and minutes from time
  const formattedTime = time.substring(0, 5);

  return [formattedDate, formattedTime];
};

export function areDatesEqual(date1: Date, date2: Date) {
  // Date 객체를 문자열로 변환하여 날짜를 비교합니다.
  const dateString1 = date1.toISOString().slice(0, 10);
  const dateString2 = date2.toISOString().slice(0, 10);

  // 두 날짜 문자열을 비교하여 같으면 true, 다르면 false를 반환합니다.
  return dateString1 === dateString2;
}

/**
 * 두 Date 객체의 날짜를 비교합니다. 시간은 고려하지 않습니다.
 *
 * @param {Date} date1 - 비교할 첫 번째 Date 객체
 * @param {Date} date2 - 비교할 두 번째 Date 객체
 * @returns {number} - 비교 결과. date1이 date2보다 미래면 1, 과거면 -1, 같으면 0을 반환
 */
export function compareDatesOnly(date1: Date, date2: Date) {
  const strippedDate1 = new Date(
    date1.getFullYear(),
    date1.getMonth(),
    date1.getDate(),
  );
  const strippedDate2 = new Date(
    date2.getFullYear(),
    date2.getMonth(),
    date2.getDate(),
  );

  if (strippedDate1.getTime() > strippedDate2.getTime()) {
    return 1; // date1이 date2보다 미래
  } else if (strippedDate1.getTime() < strippedDate2.getTime()) {
    return -1; // date1이 date2보다 과거
  } else {
    return 0; // 두 날짜가 같음
  }
}

export function getLocaleDateString(
  dateTime: string, //"2023-09-21 15:11:35"
  locale: Locale,
  translate: (
    key: string,
    variables?:
      | {
          [key: string]: any;
        }
      | undefined,
  ) => string,
) {
  const inputDate = new Date(dateTime);
  const month = inputDate.getMonth() + 1;
  const date = inputDate.getDate();

  switch (locale) {
    case 'KR':
    case 'ENG':
      return `${translate(`global_month_${month}`)} ${translate(
        '_global_date',
        { date },
      )}`;
    case 'VNM':
      return `${translate('_global_date', { date })} ${translate(
        `global_month_${month}`,
      )}`;
  }
}

/**
 * 문자열을 입력받아 Date 객체로 변환합니다.
 * Date로 변환할 수 없는 문자열이 입력되면 null을 반환합니다.
 * @param {string} dateString - Date 객체로 변환할 문자열
 * @returns {Date | null} - 변환된 Date 객체 또는 null
 */
export function stringToDate(dateString: string): Date | null {
  const date = new Date(dateString);
  return isNaN(date.getTime()) ? null : date;
}

import { Controller } from 'types/types';
import customAxios from '../customAxios';
import { getApiBaseUrl } from 'api/index';

type RequestControllerResponse = {
  controller?: Controller[];
  isSuccess: boolean;
  code: number;
  message: string;
};

type RequestUpdateControllerResponse = {
  isSuccess: boolean;
  code: number[];
  message: string;
};
type ControllerPayload = {
  farmNumber: number;
  controllerNumber: number;
};

type UpdateControllerPayload = ControllerPayload & {
  action: string;
  value: number;
};

type BatchUpdateControllersPayload = ControllerPayload & {
  actions: string[];
  values: number[];
};

type RequestControllerArgs = {
  payload: ControllerPayload;
  access: number;
};

type RequestUpdateControllerArgs = {
  payload: UpdateControllerPayload;
  access: number;
};

type RequestBatchUpdateControllersArgs = {
  payload: BatchUpdateControllersPayload;
  access: number;
};
export async function requestController(args: RequestControllerArgs) {
  const { payload, access } = args;
  const requestBody = new FormData();
  requestBody.append('farm_number', payload.farmNumber.toString());
  requestBody.append('controller_number', payload.controllerNumber.toString());
  try {
    const response = await customAxios.post<RequestControllerResponse>(
      `${getApiBaseUrl(access)}/web/controller.php`,
      requestBody,
    );
    return response.data;
  } catch (error) {
    console.error('에러가 발생하였습니다. 다시 확인해 주십시오.');
    throw error;
  }
}

export async function requestUpdateController(
  args: RequestUpdateControllerArgs,
) {
  const { payload, access } = args;
  const requestBody = new FormData();
  requestBody.append('farm_number', payload.farmNumber.toString());
  requestBody.append('controller_number', payload.controllerNumber.toString());
  requestBody.append(payload.action, payload.value.toString());
  try {
    const response = await customAxios.post<RequestUpdateControllerResponse>(
      `${getApiBaseUrl(access)}/web/update_controller.php`,
      requestBody,
    );
    return response.data;
  } catch (error) {
    ('에러가 발생하였습니다. 다시 확인해 주십시오.');
  }
}

interface RequestBatchUpdateControllersResponse {
  isSuccess: boolean;
  code: number[];
  message: string[];
}
// 여러 개의 컨트롤러를 한 번에 수정하는 요청
export async function requestBatchUpdateControllers(
  args: RequestBatchUpdateControllersArgs,
) {
  const { payload, access } = args;
  const { actions, values } = payload;
  const requestBody = new FormData();
  requestBody.append('farm_number', payload.farmNumber.toString());
  requestBody.append('controller_number', payload.controllerNumber.toString());
  if (actions.length !== values.length) return;
  actions.forEach((action, index) =>
    requestBody.append(action, values[index].toString()),
  );
  try {
    const response =
      await customAxios.post<RequestBatchUpdateControllersResponse>(
        `${getApiBaseUrl(access)}/web/update_controller.php`,
        requestBody,
      );
    return response.data;
  } catch (error) {
    console.error('에러가 발생하였습니다. 다시 확인해 주십시오.');
    throw error;
  }
}

// 레일타입 농장 구조 관련 쿼리

import { useMutation, useQueryClient } from '@tanstack/react-query';
import { insertRailDevice } from 'api/master/insertRailDevice';
import { RAIL_STRUCTURE_KEY } from '../worm/railStructureQuery';
import { useAllFarmListQuery } from './allFarmListQuery';

type Params = {
  targetFarmNumber: number;
};

// 레일타입 농장을 추가하는 쿼리 훅
export const useInsertRailTypeMutation = ({ targetFarmNumber }: Params) => {
  const queryClient = useQueryClient();
  //targetAccess: 컨트롤러를 추가할 농장의 서버번호
  const { data: masterFarms } = useAllFarmListQuery();
  const targetAccess = masterFarms?.find(
    (farm) => farm.id === targetFarmNumber,
  )?.access;
  return useMutation({
    mutationFn: async (
      payload: Omit<
        Parameters<typeof insertRailDevice>[0],
        'farmNumber' | 'access'
      >,
    ) => {
      if (targetAccess === undefined) throw new Error('no  targetAccess');
      await insertRailDevice({
        farmNumber: targetFarmNumber,
        ...payload,
        access: targetAccess,
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries([RAIL_STRUCTURE_KEY, targetFarmNumber]);
    },
  });
};

import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { deleteFarm } from 'api/master/deleteFarm';
import { getFarms } from 'api/master/getFarm';
import { insertFarm } from 'api/master/insertFarm';
import { IS_PRODUCTION } from 'constants/index';
import useFarmStore from 'store/farmStore';

// DB에 있는 모든 농장 목록을 쿼리하는 쿼리 훅
export const MASTER_FARM_QUERY_KEY = 'farmList';
export const useAllFarmListQuery = () => {
  const { access } = useFarmStore();
  return useQuery({
    queryKey: [MASTER_FARM_QUERY_KEY],
    queryFn: async () => {
      const farms = await getFarms({ access });
      return farms;
    },
  });
};

// 농장을 추가하는 쿼리 훅
export const useInsertFarmMutation = () => {
  const { access } = useFarmStore(); //현재 접속중인 서버번호
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (
      payload: Parameters<typeof insertFarm>[0]['payload'],
    ) => {
      await insertFarm({ payload, access });
      //
      if (IS_PRODUCTION && payload.access !== 0)
        //실제 서버에 추가할 경우, 새 농장에 부여되는 서버가 0번이 아니라면, 해당 서버에도 농장을 추가하는 요청을 보낸다.
        await insertFarm({ payload, access: payload.access });
    },

    onSuccess: () => {
      queryClient.refetchQueries([MASTER_FARM_QUERY_KEY]);
    },
  });
};

//농장을 삭제하는 쿼리 훅
export const useDeleteFarmMutation = () => {
  const queryClient = useQueryClient();
  const { data: masterFarms } = useAllFarmListQuery();

  return useMutation({
    mutationFn: async (farmNumber: number) => {
      //targetAccess: 컨트롤러를 삭제할 농장의 서버번호
      const targetAccess = masterFarms?.find(
        (farm) => farm.id === farmNumber,
      )?.access;
      if (targetAccess === undefined)
        throw new Error('no selectedFarm or targetAccess');
      await deleteFarm({
        payload: { farnNumber: farmNumber },
        access: targetAccess,
      });
    },
    onSuccess: () => {
      queryClient.refetchQueries([MASTER_FARM_QUERY_KEY]);
    },
  });
};

//getFarms api를 이용해 사용자가 master권한이 있는지 확인하는 훅 (key는 동일)
export const useIsMasterQuery = () => {
  const { access } = useFarmStore();
  return useQuery({
    queryKey: [MASTER_FARM_QUERY_KEY, 'isMaster'],
    queryFn: async () => {
      try {
        const farms = await getFarms({ access });
        return !!farms;
      } catch (error) {
        return false;
      }
    },
  });
};

import React, { ReactNode, Suspense, SuspenseProps } from 'react';
import {
  ErrorBoundary,
  ErrorBoundaryPropsWithRender,
} from 'react-error-boundary';

// 오류 경계의 속성 중 fallback 관련 속성을 제외한 타입을 정의합니다.
type ExceptFallbackErrorBoundaryAttributes = Omit<
  ErrorBoundaryPropsWithRender,
  'fallbackRender' | 'fallback' | 'FallbackComponent'
>;

// AsyncBoundary 컴포넌트의 속성을 정의합니다.
type AsyncBoundaryProps = {
  children: ReactNode; // 자식 컴포넌트
  ErrorFallback?: ErrorBoundaryPropsWithRender['fallbackRender']; // 오류 발생 시 보여줄 UI
  SuspenseFallback?: SuspenseProps['fallback']; // 리소스 로드 중 보여줄 UI
} & ExceptFallbackErrorBoundaryAttributes;

function AsyncBoundary({
  children,
  ErrorFallback = () => <div></div>,
  SuspenseFallback,
  ...restErrorBoundaryAttributes
}: AsyncBoundaryProps) {
  return (
    // 오류 발생 시 대체 UI를 표시하는 오류 경계
    <ErrorBoundary
      fallbackRender={ErrorFallback}
      {...restErrorBoundaryAttributes}
    >
      {/* 리소스가 로드될 때까지 대체 UI를 표시하는 Suspense */}
      <Suspense fallback={SuspenseFallback}>{children}</Suspense>
    </ErrorBoundary>
  );
}

export default AsyncBoundary;

import { DefaultResponse } from 'api';
import customAxios from 'api/customAxios';
import { Access, Farm } from 'types/types';
import { masterAuthUrl } from '.';

export type MasterFarm = Pick<
  Farm,
  'id' | 'farm_name' | 'farm_address' | 'country'
> & {
  access: Access;
};

type Payload = {
  access: Access;
};
/**
 * 관리자 계정으로 로그인한 경우, 모든 농장의 아이디, 이름, 주소를 가져오는 함수
 */
export const getFarms = async ({ access }: Payload) => {
  try {
    const response = await customAxios.post<
      { farm?: MasterFarm[] } & DefaultResponse
    >(`${masterAuthUrl(access)}/getFarm.php`);

    if (response.data.isSuccess === false) {
      throw new Error(response.data.message);
    }

    const farm = response.data.farm || [];
    return farm;
  } catch (error) {
    console.error('Request Master Auth Farm error', error);
    throw error;
  }
};

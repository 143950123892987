import clsx from 'clsx';
import { useInsertFarmMutation } from 'hooks/reactQuery/master/allFarmListQuery';
import React from 'react';
import useFarmAddFormStore from 'store/master/farmAddFormStore';
import AccessSelect from '../AccessSelect';

type Props = {
  onClose: () => void;
};
export default function FarmAddForm({ onClose }: Props) {
  const { mutateAsync } = useInsertFarmMutation(); // 농장 추가 훅
  const {
    name,
    address,
    country,
    access,
    setName,
    setAddress,
    setCountry,
    setAccess,
    init,
  } = useFarmAddFormStore();

  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (access === undefined) return;
    await mutateAsync({
      farmName: name,
      farmAddress: address,
      country,
      access,
    });
    onClose();
  };

  const onReset = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    init();
    onClose();
  };

  return (
    <form
      id="master-main-farm-edit-info"
      className="flex flex-col gap-y-3 "
      onSubmit={onSubmit}
      onReset={onReset}
    >
      <h2 className="text-lg font-bold">농장정보</h2>
      <div className="grid grid-cols-2 gap-x-30px gap-y-1">
        <div className="flex gap-x-0.5 items-center">
          <label htmlFor="farm-add-number" className={labelStyle}>
            번호
          </label>
          <input
            id="farm-add-number"
            className={inputStyle}
            disabled
            value={'-'}
          />
        </div>
        <div className="flex gap-x-0.5 items-center">
          <label htmlFor="farm-add-country" className={labelStyle}>
            국가
          </label>
          <input
            id="farm-add-country"
            className={inputStyle}
            placeholder="대한민국"
            value={country}
            onChange={(e) => setCountry(e.target.value)}
          />
        </div>
        <div className="flex gap-x-0.5 items-center">
          <label htmlFor="farm-add-name" className={labelStyle}>
            농장명
          </label>
          <input
            id="farm-add-name"
            className={inputStyle}
            placeholder="로얄라미 논산"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </div>
        <div className="flex gap-x-0.5 items-center">
          <label htmlFor="farm-add-server-number" className={labelStyle}>
            서버번호
          </label>
          <AccessSelect
            selectedAccess={access}
            onSelect={(newAccess) => setAccess(newAccess)}
          />
        </div>
        <div className="flex gap-x-0.5 items-center col-span-2">
          <label htmlFor="farm-add-address" className={labelStyle}>
            농장주소
          </label>
          <input
            id="farm-add-address"
            className={inputStyle}
            placeholder="경기도 성남시 분당구 판교로289번길 20, 2동 8층"
            value={address}
            onChange={(e) => setAddress(e.target.value)}
          />
        </div>
      </div>
      <div className="flex gap-x-2.5">
        <button
          type="reset"
          className={clsx(buttonStyle, 'text-subText bg-subLine flex-[0.3]')}
        >
          취소
        </button>
        <button
          type="submit"
          className={clsx(buttonStyle, 'text-white bg-mainGreen flex-[0.7]')}
        >
          생성
        </button>
      </div>
    </form>
  );
}

// style
const labelStyle = clsx('text-sm text-subText min-w-[70px] whitespace-nowrap');
const inputStyle = clsx(
  'border-subLine border rounded-md px-2.5 py-3 flex-1',
  'focus:outline-none',
  'font-bold text-sm',
  'placeholder:font-normal',
);
const buttonStyle = clsx('px-3 py-2.5 rounded-md text-sm font-medium');

import React, { useState, useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';
import { cn } from 'utils/cn';

// Option 인터페이스 정의
export interface Option {
  label: string;
  value: string | number;
}

// Props 타입 정의
type CustomSelectProps = {
  options: Option[];
  onChange: (value: string | number) => void;
  defaultValue?: string | number;
  className?: string;
  maxVisibleOptions?: number;
  dropdownClass?: string;
  zIndex?: number;
  arrowColor?: string;
};

const CustomSelect: React.FC<CustomSelectProps> = ({
  options,
  onChange,
  defaultValue,
  className,
  maxVisibleOptions = 5,
  zIndex = 20,
  arrowColor = '#282828',
  dropdownClass,
}) => {
  // 토글 상태와 선택된 값을 관리하는 state
  const [isOpen, setIsOpen] = useState(false);
  const [selectedValue, setSelectedValue] = useState(
    defaultValue || options[0].value,
  );

  // 드롭다운 메뉴의 위치를 관리하는 state
  const [dropdownPosition, setDropdownPosition] = useState({
    top: 0,
    left: 0,
    width: 0,
  });

  // 컨테이너 요소의 참조
  const containerRef = useRef<HTMLDivElement | null>(null);

  // 드롭다운 토글 함수
  const toggleDropdown = (e: React.MouseEvent) => {
    e.stopPropagation();
    setIsOpen((prev) => !prev);
  };

  // 옵션 클릭 핸들러
  const handleOptionClick = (option: Option) => {
    setSelectedValue(option.value);
    onChange(option.value);
    setIsOpen(false);
  };

  // 외부 클릭을 감지하여 드롭다운을 닫는 이펙트
  useEffect(() => {
    const handleOutsideClick = (event: any) => {
      //click event가 발생한 지점이 드롭다운 내의 옵션인 경우 - 드롭다운 내부에서 발생한 이벤트이므로 무시
      const dropdownOptionNodes = Array.from(
        document.querySelectorAll('.dropdown-option'),
      );
      if (dropdownOptionNodes.includes(event.target)) {
        return;
      }
      if (
        containerRef.current &&
        !containerRef.current.contains(event.target)
      ) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleOutsideClick);
    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, []);

  // 드롭다운의 위치를 계산하는 이펙트
  useEffect(() => {
    if (containerRef.current) {
      const rect = containerRef.current.getBoundingClientRect();
      setDropdownPosition({
        top: rect.bottom + window.scrollY,
        left: rect.left + window.scrollX,
        width: rect.width,
      });
    }
  }, [isOpen]);

  // 기본 값 설정 이펙트
  useEffect(() => {
    setSelectedValue(defaultValue || options[0].value);
  }, [defaultValue, options]);

  // 드롭다운 메뉴 렌더링
  const overflowY = options.length <= maxVisibleOptions ? 'hidden' : 'scroll';
  const dropdown = isOpen && (
    <div
      style={{
        position: 'absolute',
        zIndex: zIndex,
        top: `${dropdownPosition.top}px`,
        left: `${dropdownPosition.left}px`,
        width: `${dropdownPosition.width}px`,
        maxHeight: `${maxVisibleOptions * 40}px`,
        overflowY: overflowY,
      }}
      className="w-full overflow-hidden border rounded-lg shadow"
    >
      {options.map((option) => (
        <div
          key={option.value}
          className={cn(
            'px-3 py-2 truncate bg-white cursor-pointer dropdown-option hover:bg-gray-200',
            dropdownClass,
          )}
          onClick={(e) => {
            e.stopPropagation();
            handleOptionClick(option);
          }}
        >
          {option.label}
        </div>
      ))}
    </div>
  );

  const selectClass = cn(
    'flex items-center px-3 py-2 w-full justify-between',
    'border rounded cursor-pointer border-subLine',
    className,
  );
  return (
    <div className="relative w-full select-none" ref={containerRef}>
      <div className={`${selectClass}`} onClick={toggleDropdown}>
        <span className="flex-1 truncate">
          {options.find((option) => option.value === selectedValue)?.label}
        </span>
        {/* 아이콘 렌더링 */}
        <div className="">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="8"
            height="7"
            viewBox="0 0 8 7"
            fill="none"
            className="flex-shrink-0"
          >
            <path
              d="M4.49041 6.72C4.27245 7.09333 3.72755 7.09333 3.50959 6.72L0.0767116 0.839999C-0.141249 0.466666 0.131202 -3.76869e-08 0.567123 0L7.43288 5.93569e-07C7.8688 6.31256e-07 8.14125 0.466667 7.92329 0.840001L4.49041 6.72Z"
              fill={arrowColor}
            />
          </svg>
        </div>
      </div>
      {/* 드롭다운 메뉴를 Portal을 사용하여 렌더링 */}
      {ReactDOM.createPortal(dropdown, document.body)}
    </div>
  );
};

export default CustomSelect;

import { getApiBaseUrl } from 'api/index';
import customAxios from './customAxios';
import { RelaySetting, RelayType } from 'types/types';

// RelayResponse 타입 정의
type RelayResponse = {
  relay_setting: RelaySetting; // 연결장치 설정 정보
  isSuccess: boolean;
  code: number;
  message: string;
};

// UpdateRelayStatusResponse 타입 정의
type UpdateRelayStatusResponse = {
  isSuccess: boolean;
  code: number;
  message: string;
};

// UpdateRelayTypeResponse 타입 정의
type UpdateRelayTypeResponse = {
  isSuccess: boolean;
  code: number;
  message: string;
};

type RelayPayload = {
  farmNumber: number;
  controllerNumber: number;
};

type UpdateRelayStatusPayload = RelayPayload & {
  relayNumber: number;
};

type UpdateRelayTypePayload = UpdateRelayStatusPayload & {
  relayType: RelayType;
};

type RequestRelayArgs = {
  payload: RelayPayload;
  access: number;
};

type RequestUpdateRelayStatusArgs = {
  payload: UpdateRelayStatusPayload;
  access: number;
};

type RequestUpdateRelayTypeArgs = {
  payload: UpdateRelayTypePayload;
  access: number;
};
// 연결장치 정보를 요청하는 함수
export async function requestRelay({ payload, access }: RequestRelayArgs) {
  const { farmNumber, controllerNumber } = payload;
  const requestBody = new FormData();
  requestBody.append('farm_number', farmNumber.toString());
  requestBody.append('controller_number', controllerNumber.toString());
  try {
    const response = await customAxios.post<RelayResponse>(
      `${getApiBaseUrl(access)}/web/relay.php`,
      requestBody,
    );
    return response.data; // 연결장치 정보 반환
  } catch (error) {
    console.error('에러가 발생하였습니다. 다시 확인해 주십시오.');
    throw error;
  }
}

// 연결장치 상태를 업데이트하는 함수
export async function requestUpdateRelayStatus({
  payload,
  access,
}: RequestUpdateRelayStatusArgs) {
  const { farmNumber, controllerNumber, relayNumber } = payload;
  const requestBody = new FormData();
  requestBody.append('farm_number', farmNumber.toString());
  requestBody.append('controller_number', controllerNumber.toString());
  requestBody.append('relay_number', relayNumber.toString());
  try {
    const response = await customAxios.post<UpdateRelayStatusResponse>(
      `${getApiBaseUrl(access)}/web/update_relay_status.php`,
      requestBody,
    );
    return response.data; // 업데이트된 상태 정보 반환
  } catch (error) {
    console.error('에러가 발생하였습니다. 다시 확인해 주십시오.');
    throw error;
  }
}

// 연결장치 타입을 업데이트하는 함수
export async function requestUpdateRelayType({
  payload,
  access,
}: RequestUpdateRelayTypeArgs) {
  const { farmNumber, controllerNumber, relayNumber, relayType } = payload;
  const requestBody = new FormData();
  requestBody.append('farm_number', farmNumber.toString());
  requestBody.append('controller_number', controllerNumber.toString());
  requestBody.append('relay_number', relayNumber.toString());
  requestBody.append('relay_type', relayType.toString());
  try {
    const response = await customAxios.post<UpdateRelayTypeResponse>(
      `${getApiBaseUrl(access)}/web/update_relay_type.php`,
      requestBody,
    );
    return response.data; // 업데이트된 타입 정보 반환
  } catch (error) {
    console.error('에러가 발생하였습니다. 다시 확인해 주십시오.');
    throw error;
  }
}

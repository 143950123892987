import { Access } from 'types/types';

export const AccessList: {
  id: Access;
  [key: string]: number | string;
}[] = [
  {
    id: 0,
    name: '대한민국',
    code: 'access_0',
  },
  {
    id: 1,
    name: '베트남',
    code: 'access_1',
  },
  {
    id: 2,
    name: '',
    code: 'access_2',
  },
];

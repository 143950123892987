import Initializing from 'components/fallback/Initializing';
import { Suspense, lazy } from 'react';
import useLoginStore from 'store/loginStore';
import NoneActiveFarm from 'components/NoneActiveFarm';
import InAppBrowswerContents from 'pages/InAppBrowserContents';
import useFarmStore from 'store/farmStore';
import {
  isCrickbotType,
  isHubType,
  isRackType,
  isRailType,
} from 'utils/userType';

const Main = lazy(() => import('pages/Main'));
const Terms = lazy(() => import('pages/terms/Terms'));
const Landing = lazy(() => import('pages/landing/Landing'));
const Routing = ({ userName }: { userName: string }) => {
  // 앱 브라우저나 웹뷰를 식별
  const isInAppBrowser = navigator.userAgent.match(
    /inapp|NAVER|KAKAOTALK|Snapchat|Line|WirtschaftsWoche|Thunderbird|Instagram|everytimeApp|WhatsApp|Electron|wadiz|AliApp|zumapp|iPhone(.*)Whale|Android(.*)Whale|kakaostory|band|twitter|DaumApps|DaumDevice\/mobile|FB_IAB|FB4A|FBAN|FBIOS|FBSS\/[^1]/i,
  );
  const { email, isGoogleUser, isLoginSuccess, isLoginLoading } =
    useLoginStore();

  const { userTypes, currentFarmNumber } = useFarmStore();
  const currentUserType = userTypes[currentFarmNumber];

  if (isInAppBrowser) return <InAppBrowswerContents />;

  if (!isGoogleUser)
    return (
      <Suspense>
        <Landing />
      </Suspense>
    );
  if (isLoginLoading) return <Initializing />;
  if (!isLoginSuccess) return <Terms userEmail={email} userName={userName} />;
  // 반달허브 농장 권한 존재 여부 확인
  if (
    !isHubType(currentUserType) &&
    !isRackType(currentUserType) &&
    !isRailType(currentUserType) &&
    !isCrickbotType(currentUserType)
  )
    return <NoneActiveFarm />;
  return (
    <Suspense>
      <Main />
    </Suspense>
  );
};

export default Routing;

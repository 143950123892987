import { DefaultResponse } from 'api';
import customAxios from 'api/customAxios';
import { masterAuthUrl } from '.';
import { Access } from 'types/types';

type Payload = {
  farmNumber: number;
  controllerNumber: number;
  targetAccess: Access; // 타겟 농장의 서버번호
};

/**
 * 농장에 컨트롤러를 추가하는 API 함수
 * @param payload
 * @returns DefaultResponse
 * @throws Error
 *
 * @example
 *  "isSuccess": true,
 *  "code": 1000,
 *  "message": "farmcontroller 추가 성공"
 */
export const insertFarmController = async (payload: Payload) => {
  const requestBody = new FormData();
  requestBody.append('farm_number', payload.farmNumber.toString());
  requestBody.append('controller_number', payload.controllerNumber.toString());
  try {
    const response = await customAxios.post<DefaultResponse>(
      `${masterAuthUrl(payload.targetAccess)}/insertFarmcontroller.php`,
      requestBody,
    );
    if (response.data.isSuccess === false) {
      throw new Error(response.data.message);
    }
    return response.data;
  } catch (error) {
    console.error('Request Master Auth Insert Farm Controller error', error);
    throw error;
  }
};

import React from 'react';
import { IcProps } from '.';

const IcLogo: React.FC<IcProps> = ({
  color = '#ECECEC',
  width = 54,
  height = 59,
  className = '',
}) => {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 54 59"
      fill="none"
    >
      <mask
        id="mask0_1663_4200"
        style={{ maskType: 'luminance' }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="54"
        height="59"
      >
        <path d="M54.0007 0.390625H0V58.6094H54.0007V0.390625Z" fill="white" />
      </mask>
      <g mask="url(#mask0_1663_4200)">
        <path
          d="M42.6855 58.6094C36.514 58.6094 31.3711 53.4799 31.3711 47.3246V11.6754C31.3711 5.52009 36.514 0.390625 42.6855 0.390625C48.857 0.390625 53.9999 5.52009 53.9999 11.6754V47.3246C53.9999 53.4799 48.857 58.6094 42.6855 58.6094Z"
          fill={color}
        />
        <path
          d="M0 30.6545H11.5716C17.4859 30.6545 22.3717 25.7815 22.3717 19.8827V11.4192C22.3717 5.52042 17.4859 0.647461 11.5716 0.647461H10.8001C4.88576 0.647461 0 5.52042 0 11.4192V30.6545Z"
          fill={color}
        />
        <path
          d="M0 58.6095H11.5716C17.7431 58.6095 22.6289 53.7366 22.6289 47.8377C22.6289 41.6825 17.7431 36.8096 11.8287 36.8096H11.3144C5.1429 36.8096 0.257162 41.6825 0.257162 47.8377V58.6095H0Z"
          fill={color}
        />
      </g>
    </svg>
  );
};
export default IcLogo;

import { Locale } from 'types/types';
import useLocaleStore from 'store/localeStore';
import engDict from 'i18n/eng/dict.json';
import engLandingDict from 'i18n/eng/dictLanding.json';
import engGlobalDict from 'i18n/eng/dictGlobal.json';
import engWormDict from 'i18n/eng/dict_worm.json';
import engDiaryDict from 'i18n/eng/dict_diary.json';
import engCrickbotDict from 'i18n/eng/dict_crickbot.json';

import krDict from 'i18n/kr/dict.json';
import krLandingDict from 'i18n/kr/dictLanding.json';
import krGlobalDict from 'i18n/kr/dictGlobal.json';
import krWormDict from 'i18n/kr/dict_worm.json';
import krDiaryDict from 'i18n/kr/dict_diary.json';
import krCrickbotDict from 'i18n/kr/dict_crickbot.json';

import vnmDict from 'i18n/vnm/dict.json';
import vnmLandingDict from 'i18n/vnm/dictLanding.json';
import vnmGlobalDict from 'i18n/vnm/dictGlobal.json';
import vnmWormDict from 'i18n/vnm/dict_worm.json';
import vnmDiaryDict from 'i18n/vnm/dict_diary.json';
import vnmCrickbotDict from 'i18n/vnm/dict_crickbot.json';

export interface LocaleDict {
  [key: string]: string | LocaleDict;
}
export type Context = 'default' | 'landing' | 'global' | 'worm' | 'crickbot';
const dict: {
  [key in Locale]: {
    default: LocaleDict;
    landing: LocaleDict;
    global: LocaleDict;
    worm: LocaleDict;
    crickbot: LocaleDict;
  };
} = {
  KR: {
    default: { ...krDict, ...krDiaryDict },
    landing: krLandingDict,
    global: krGlobalDict,
    worm: krWormDict,
    crickbot: krCrickbotDict,
  },
  ENG: {
    default: { ...engDict, ...engDiaryDict },
    landing: engLandingDict,
    global: engGlobalDict,
    worm: engWormDict,
    crickbot: engCrickbotDict,
  },
  VNM: {
    default: { ...vnmDict, ...vnmDiaryDict },
    landing: vnmLandingDict,
    global: vnmGlobalDict,
    worm: vnmWormDict,
    crickbot: vnmCrickbotDict,
  },
};
function useTranslate(context: Context = 'default') {
  const { locale } = useLocaleStore();

  const getNestedValue = (obj: any, path: string): string | undefined => {
    const keys = path.split('.'); // 점(.)을 기준으로 키를 분리
    let current = obj;

    for (const key of keys) {
      if (current[key] === undefined) {
        return undefined; // 경로가 유효하지 않으면 undefined 반환
      }
      current = current[key];
    }

    return current; // 경로의 최종 값을 반환
  };

  const translate = (key: string, variables?: { [key: string]: any }) => {
    let translation = getNestedValue(dict[locale][context], key);

    if (translation === undefined) {
      translation = key; // 키가 없으면 그대로 반환
    }

    if (variables) {
      for (const variable in variables) {
        const replaceWith = variables[variable];
        translation = translation.replace(`{${variable}}`, replaceWith);
      }
    }

    return translation;
  };

  return translate;
}

export default useTranslate;

import clsx from 'clsx';
import Toast from 'components/common/notifications/Toast';
import {
  useFarmControllerQuery,
  useInsertFarmControllerMutation,
} from 'hooks/reactQuery/master/farmControllerQuery';
import { useState } from 'react';

type Props = {
  farmId: number;
};

export default function AddControllerButton({ farmId }: Props) {
  //다음에 추가되어야하는 컨트롤러 번호
  const { data: controllers } = useFarmControllerQuery(farmId);
  const nextControllerNumber = controllers ? controllers.length + 1 : 1;
  //api 호출에 대한 응답을 표시해주는 state
  const [apiStatus, setApiStatus] = useState<
    'default' | 'loading' | 'success' | 'error'
  >('default');

  const { mutateAsync } = useInsertFarmControllerMutation(farmId);

  const onClick = async () => {
    //추가 확인 토스트
    const toastResult = await Toast.fire({
      title: '컨트롤러를 추가하시겠습니까?',
      icon: 'question',
      showCancelButton: true,
      showConfirmButton: true,
      confirmButtonText: '네',
      cancelButtonText: '아니요',
      timerProgressBar: false,
      timer: 10000,
      position: 'center',
    });
    if (toastResult.isConfirmed) {
      await addController();
    }
  };
  const addController = async () => {
    setApiStatus('loading');
    try {
      await mutateAsync({ newControllerNumber: nextControllerNumber });
      setApiStatus('success');
    } catch (error) {
      console.error('AddControllerButton error', error);
      setApiStatus('error');
    } finally {
      setTimeout(() => {
        setApiStatus('default');
      }, 2000);
    }
  };

  return (
    <button className={getButtonStyle(apiStatus)} onClick={onClick}>
      {apiStatus === 'default' && `컨트롤러 추가+`}
      {apiStatus === 'loading' && `로딩중...`}
      {apiStatus === 'success' && `추가완료!`}
      {apiStatus === 'error' && `추가실패`}
    </button>
  );
}

const getButtonStyle = (apiStatus: string) =>
  clsx(
    'p-2.5 rounded-md bg-subLine text-subText font-bold',
    apiStatus === 'loading' && 'bg-gray-300 text-white',
    apiStatus === 'success' && 'bg-green-400 text-white',
    apiStatus === 'error' && 'bg-red-400 text-white',
  );

import React, { ReactNode, useEffect } from 'react';
import ReactDOM from 'react-dom'; // 추가
import { cn } from '../../utils/cn';
import clsx from 'clsx';
import { IcCloseCross } from '../icon/IcCloseCross';
import useLocaleStore from 'store/localeStore';

type ModalProps = {
  open?: boolean;
  onClose: () => void;
  children?: ReactNode;
  containerClass?: string;
  closeButton?: boolean;
  closeButtonClass?: string;
  outerClass?: string;
};

const Modal: React.FC<ModalProps> = ({
  open,
  onClose,
  children,
  containerClass,
  closeButton = true,
  closeButtonClass = '',
  outerClass = '',
}) => {
  const animate = clsx('animate-modalOpen');
  const container = cn(
    'relative',
    'z-50 bg-[#FCFCFC] p-5 text-black shadow-lg',
    'rounded-md',
    containerClass,
    animate,
  );
  const { isVietnamese } = useLocaleStore();
  useEffect(() => {
    // 모달이 마운트될 때 body의 overflow를 hidden으로 설정
    document.body.style.overflow = 'hidden';

    // 모달이 언마운트될 때 overflow 설정을 원상복구
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, []); // 빈 dependency 배열을 사용하여 이 효과를 마운트/언마운트 시에만 실행

  if (open === false) return null;

  const modalContent = (
    <div
      className={clsx(
        { 'font-noto': !isVietnamese },
        'fixed inset-0 z-40 flex items-center justify-center whitespace-nowrap animate-modalOpen',
      )}
    >
      <div
        onClick={(e) => {
          e.stopPropagation();
          onClose();
        }}
        className={clsx('fixed inset-0 z-40 bg-black opacity-25', outerClass)}
      ></div>
      <div className={container}>
        {closeButton && (
          <button
            className={clsx('absolute right-5 top-5', closeButtonClass)}
            onClick={(e) => {
              e.stopPropagation();
              onClose();
            }}
          >
            <IcCloseCross />
          </button>
        )}
        {children}
      </div>
    </div>
  );

  // ReactDOM.createPortal을 사용하여 modal-root div에 모달 내용 렌더링
  const rootElement = document.getElementById('modal-root');

  if (!rootElement) {
    throw new Error("Can't find modal root element!");
  }

  return ReactDOM.createPortal(modalContent, rootElement);
};

export default Modal;

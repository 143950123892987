import { create } from 'zustand';
import { createJSONStorage, devtools, persist } from 'zustand/middleware';

interface NavStoreState {
  selectedNavId: number;
  setSelectedNavId: (id: number) => void;
}
const useNavStore = create<NavStoreState>()(
  devtools(
    persist(
      (set) => ({
        selectedNavId: 0,
        setSelectedNavId: (id) => {
          set(() => ({
            selectedNavId: id,
          }));
        },
      }),
      { name: 'nav-store', storage: createJSONStorage(() => sessionStorage) },
    ),
  ),
);

export default useNavStore;

import { MasterUser } from 'api/master/getUser';
import clsx from 'clsx';
import useSelectedUserStore from 'store/master/selectedUserStore';

type Props = {
  user: MasterUser;
};

export default function UserItem({ user }: Props) {
  const { setSelectedUser } = useSelectedUserStore();

  return (
    <div
      id={`user-item-${user.id}`}
      className={styles}
      onClick={() => {
        setSelectedUser(user);
      }}
    >
      <div
        id={`user-item-${user.id}-number`}
        className="w-[50px]  text-sm text-subText"
      >
        {user.id}
      </div>
      <div
        id={`user-item-${user.id}-name`}
        className="w-[170px] font-bold text-sm"
      >
        {user.user}
      </div>
      <div
        id={`user-item-${user.id}-address`}
        className="w-[80px] text-sm text-subText text-center"
      >
        {user.access}
      </div>
      <div
        id={`user-item-${user.id}-country`}
        className="w-[80px] text-sm text-subText text-center"
      >
        {user.selected_id}
      </div>
      <div
        id={`user-item-${user.id}-email`}
        className="w-[200px] text-sm text-subText"
      >
        {user.email}
      </div>
    </div>
  );
}
// style
const styles = clsx(
  'flex gap-x-[11px] px-15px py-3 items-center',
  'hover:bg-subLine',
);

import { Access } from 'types/types';
import { masterAuthUrl } from '.';
import customAxios from 'api/customAxios';
import { DefaultResponse } from 'api';

type Payload = {
  access: Access;
  farmName: string;
  farmAddress: string;
  country: string;
};
export const insertFarm = async ({
  payload,
  access,
}: {
  payload: Payload;
  access: Access;
}) => {
  const requestBody = new FormData();
  requestBody.append('farm_name', payload.farmName);
  requestBody.append('farm_address', payload.farmAddress);
  requestBody.append('country', payload.country);
  requestBody.append('access', String(payload.access));
  try {
    const response = await customAxios.post<
      DefaultResponse & { farmNumber?: number }
    >(`${masterAuthUrl(access)}/insertFarm.php`, requestBody);
    if (response.data.isSuccess === false) {
      throw new Error(response.data.message);
    }
    return response.data;
  } catch (error) {
    console.error('Request Master Auth Insert Farm error', error);
    throw error;
  }
};

import { create } from 'zustand';

//농장 리스트 페이지에서 사용하는 store
interface FarmListPageState {
  currentPage: number;
  setCurrentPage: (newPage: number) => void;
  farmPerPage: number;
  setFarmPerPage: (newFarmPerPage: number) => void;
}

const useFarmListPageStore = create<FarmListPageState>((set) => ({
  currentPage: 1,
  setCurrentPage: (newPage: number) => set({ currentPage: newPage }),
  farmPerPage: 10,
  setFarmPerPage: (newFarmPerPage: number) =>
    set({ farmPerPage: newFarmPerPage }),
}));

export default useFarmListPageStore;

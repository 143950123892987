// 사용자가 보유한 농장들 중에 보여지는 농장을 선택하는 컴포넌트
import clsx from 'clsx';
import CustomSelect from 'components/common/CustomSelect';
import { useFarmsQuery } from 'hooks/reactQuery/farmQuery';
import useFarmStore from 'store/farmStore';

export default function FarmNumberSelect() {
  const { farmNumbers, currentFarmNumber, changeFarmNumber } = useFarmStore();
  const farmsResponse = useFarmsQuery();
  const farms = farmsResponse.data;

  const farmNumberOptions = farmNumbers.map((farmNumber, index) => ({
    value: farmNumber,
    label: farms?.[index]?.farm_name ?? `${farmNumber}번 농장`,
  }));
  const onChange = (value: number | string) => {
    farmNumbers.includes(Number(value)) && changeFarmNumber(Number(value));
  };

  return (
    <CustomSelect
      className={className}
      options={farmNumberOptions}
      defaultValue={currentFarmNumber}
      onChange={onChange}
      zIndex={1000}
    />
  );
}

// style
const className = clsx(
  'py-3 px-4',
  'xs:py-[15px] xs:px-5 xs:gap-[35px]',
  'max-w-[166px] rounded-full borderSubline',
  'text-xs xs:text-sm md:text-base',
);

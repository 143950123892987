import { IcAlarmBell } from 'components/icon/IcAlarmBell';
import AlertCounter from './AlertCounter';
import { useRef, useState } from 'react';
import AlarmListContainer from './AlarmListContainer';
import AsyncBoundary from 'components/common/AsyncBoundary';
import { useOutsideClick } from 'hooks/event/useOutsideClick';

export default function Alarm() {
  const [showAlarmList, setShowAlarmList] = useState<boolean>(false);
  const alarmListRef = useRef<HTMLDivElement>(null);

  useOutsideClick(alarmListRef, () => setShowAlarmList(false));
  return (
    <div
      className="relative rounded-full shadow-main p-15px w-[76px]"
      onClick={() => setShowAlarmList(true)}
    >
      <div className="relative flexCenter">
        <IcAlarmBell />
        <AsyncBoundary>
          <AlertCounter />
        </AsyncBoundary>
      </div>
      {showAlarmList && <AlarmListContainer ref={alarmListRef} />}
    </div>
  );
}

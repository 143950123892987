import { isLanguage, Language } from 'types/types';
import Select from 'react-select';
import { authSelectStyle } from './AuthSelect';
import { OptionType } from 'utils/select';

type Props = {
  selectedLanguage: Language;
  onSelect: (newLanguage: Language) => void;
};

export default function LanguageSelect({ selectedLanguage, onSelect }: Props) {
  const languageOptions: OptionType[] = [
    { value: '1', label: '한국어' },
    { value: '2', label: '영어' },
    { value: '3', label: '베트남어' },
  ];
  return (
    <Select
      className="flex flex-1 h-full"
      styles={authSelectStyle}
      options={languageOptions}
      value={languageOptions.find(
        (lang) => lang.value === `${selectedLanguage}`,
      )}
      onChange={(selectedOption) => {
        if (!selectedOption) return;
        const newLanguage = Number(selectedOption.value);
        isLanguage(newLanguage) && onSelect(newLanguage);
      }}
      components={{
        IndicatorSeparator: () => null,
      }}
    />
  );
}

import { getApiBaseUrl } from 'api';
import customAxios from './customAxios';
import { Access, Cell, CellDetail, CellType } from 'types/types';

interface CellLatestPayload {
  farmNumber: number;
  lineNumber?: number;
}

interface UpdateCellPayload {
  farmNumber: number;
  lineNumber?: number;
  blockNumber?: number;
  floorNumber?: number;
  cellNumbers?: number[];
  status: number;
}

interface UpdateCellWithDatePayload {
  farmNumber: number;
  lineNumber: number;
  blockNumber: number;
  floorNumber: number;
  status: number;
  startDate: string;
}

interface UpdateCellAdminPayload extends UpdateCellWithDatePayload {
  resetCount: number;
  cellType: number;
}

interface RequestCellLatestResponse {
  cell?: Cell[];
}
interface RequestCellByLineResponse {
  cell?: CellDetail[];
}

// API 기본 경로 생성 함수
const apiBaseUrl = (access: Access) => `${getApiBaseUrl(access)}/webpage`;

// 최신 셀 데이터 요청
export const requestCellLatest = async ({
  payload,
  access = 0,
}: {
  payload: CellLatestPayload;
  access: Access;
}) => {
  const requestBody = new FormData();
  requestBody.append('farm_number', payload.farmNumber.toString());
  payload.lineNumber &&
    requestBody.append('line_number', payload.lineNumber.toString());
  try {
    const response = await customAxios.post<RequestCellLatestResponse>(
      `${apiBaseUrl(access)}/cell_latest.php`,
      requestBody,
    );
    return response.data.cell || [];
  } catch (error) {
    console.error('RequestCellData error', error);
    throw error;
  }
};

// 라인별로 최신 셀 데이터 요청
export const requestCellLatestByLine = async ({
  payload,
  access = 0,
}: {
  payload: CellLatestPayload;
  access: Access;
}) => {
  const requestBody = new FormData();
  requestBody.append('farm_number', payload.farmNumber.toString());
  payload.lineNumber &&
    requestBody.append('line_number', payload.lineNumber.toString());
  try {
    const response = await customAxios.post<RequestCellByLineResponse>(
      `${apiBaseUrl(access)}/cell_latest.php`,
      requestBody,
    );
    return response.data.cell || [];
  } catch (error) {
    console.error('RequestCellData error', error);
    throw error;
  }
};

interface UpdateCellResponse {
  isSuccess?: boolean;
  code?: number;
  message?: string;
}

// 셀 업데이트 요청
export const requestUpdateCell = async ({
  payload,
  access = 0,
}: {
  payload: UpdateCellPayload;
  access: Access;
}) => {
  const requestBody = new FormData();
  const { farmNumber, lineNumber, blockNumber, floorNumber, cellNumbers } =
    payload;
  requestBody.append('selected_farm', farmNumber.toString());
  //cellNumbers가 null이 아닌 경우에만
  if (cellNumbers) {
    requestBody.append('cell_number', `${cellNumbers}`);
  } else {
    lineNumber && requestBody.append('selected_line', lineNumber.toString());
    blockNumber && requestBody.append('selected_block', blockNumber.toString());
    floorNumber && requestBody.append('selected_floor', floorNumber.toString());
  }
  requestBody.append('status', payload.status.toString());
  try {
    const res = await customAxios.post<UpdateCellResponse>(
      `${apiBaseUrl(access)}/update_cell.php`,
      requestBody,
    );
    return res.data;
  } catch (error) {
    console.error('UpdateCellData error', error);
    throw error;
  }
};

// 시간 포함 셀 업데이트 요청
export const requestUpdateCellwithDate = async ({
  payload,
  access = 0,
}: {
  payload: UpdateCellWithDatePayload;
  access: Access;
}): Promise<void> => {
  const requestBody = new FormData();
  requestBody.append('selected_farm', payload.farmNumber.toString());
  requestBody.append('selected_line', payload.lineNumber.toString());
  requestBody.append('selected_block', payload.blockNumber.toString());
  requestBody.append('selected_floor', payload.floorNumber.toString());
  requestBody.append('status', payload.status.toString());
  requestBody.append('startdate', payload.startDate.toString());

  try {
    await customAxios.post(
      `${apiBaseUrl(access)}/update_date.php`,
      requestBody,
    );
  } catch (error) {
    console.error('UpdateCellwithDate error', error);
    throw error;
  }
};
// 관리자 기능에서 업데이트 요청하는 함수
export const requestUpdateCellAdmin = async ({
  payload,
  access,
}: {
  payload: UpdateCellAdminPayload;
  access: Access;
}) => {
  const requestBody = new FormData();
  requestBody.append('selected_farm', payload.farmNumber.toString());
  requestBody.append('selected_line', payload.lineNumber.toString());
  requestBody.append('selected_block', payload.blockNumber.toString());
  requestBody.append('selected_floor', payload.floorNumber.toString());
  requestBody.append('status', payload.status.toString());
  requestBody.append('startdate', payload.startDate.toString());
  requestBody.append('reset_count', payload.resetCount.toString());
  requestBody.append('cell_type', payload.cellType.toString());

  try {
    await customAxios.post(
      `${apiBaseUrl(access)}/update_cell_admin.php`,
      requestBody,
    );
  } catch (error) {
    console.error('Update Cell Admin error', error);
    throw error;
  }
};
//관리자: line에 해당하는 cell 전체의 cellType을 변경
export const requestInsertCellType = async ({
  payload,
  access,
}: {
  payload: { farmNumber: number; lineNumber: number; cellType: CellType };
  access: Access;
}) => {
  const requestBody = new FormData();
  requestBody.append('farm_number', payload.farmNumber.toString());
  requestBody.append('line_number', payload.lineNumber.toString());
  requestBody.append('cell_type', payload.cellType.toString());

  try {
    await customAxios.post(
      `${apiBaseUrl(access)}/insert_celltype.php`,
      requestBody,
    );
  } catch (error) {
    console.error('Insert Celltype error', error);
    throw error;
  }
};

import { Access } from 'types/types';
import { create } from 'zustand';

// 농장 정보에 대한 타입
export interface FarmInfoState {
  id: number;
  country: string;
  name: string;
  address: string;
  access?: Access;
  setFarmInfo: (
    farmInfo: Partial<
      Pick<FarmInfoState, 'id' | 'country' | 'name' | 'address' | 'access'>
    >,
  ) => void;
  isCountryChanged: boolean;
  setIsCountryChanged: (isCountryChanged: boolean) => void;
}

//zustand
const useFarmEditInfoStore = create<FarmInfoState>((set) => ({
  id: 0,
  country: '',
  name: '',
  address: '',
  access: undefined,
  setFarmInfo: (farmInfo) => set(farmInfo),
  isCountryChanged: false,
  setIsCountryChanged: (isCountryChanged) => set({ isCountryChanged }),
}));

export default useFarmEditInfoStore;

import { DefaultResponse } from 'api';
import customAxios from 'api/customAxios';
import { Access } from 'types/types';
import { masterAuthUrl } from '.';

type Payload = {
  farmNumber: number;
  lineCount: number;
  blockCount: number;
  boxCount: number;
  access: Access;
};
export const insertRailDevice = async (payload: Payload) => {
  const requestBody = new FormData();
  requestBody.append('farmNumber', payload.farmNumber.toString());
  requestBody.append('lineCount', payload.lineCount.toString());
  requestBody.append('blockCount', payload.blockCount.toString());
  requestBody.append('boxCount', payload.boxCount.toString());

  try {
    const response = await customAxios.post<DefaultResponse>(
      `${masterAuthUrl(payload.access)}/insertRailDevice.php`,
      requestBody,
    );
    if (response.data.isSuccess === false) {
      throw new Error(response.data.message);
    }
    return response.data;
  } catch (error) {
    console.error('Request Master Auth Insert Rail Device error', error);
    throw error;
  }
};

export const IcCloseCross = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="30"
    height="30"
    viewBox="0 0 30 30"
    fill="none"
  >
    <path
      d="M1 1L29 29"
      stroke="#5F5F5F"
      strokeWidth="2"
      strokeLinecap="round"
    />
    <path
      d="M29 1L0.999999 29"
      stroke="#5F5F5F"
      strokeWidth="2"
      strokeLinecap="round"
    />
  </svg>
);

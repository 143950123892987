import React, { ReactNode } from 'react';
import { cn } from 'utils/cn';

type ButtonSize = 'xsmall' | 'small' | 'medium' | 'large';

type ButtonProps = {
  size?: ButtonSize;
  color?: string;
  onClick?: (e?: React.MouseEvent) => void;
  children?: ReactNode;
  className?: string;
};

const Button: React.FC<ButtonProps> = ({
  size = 'medium',
  color,
  onClick,
  children,
  className,
}) => {
  const getSizeClassName = (): string => {
    switch (size) {
      case 'xsmall':
        return 'px-4 py-1.5 text-xs rounded-lg';
      case 'small':
        return 'px-[15px] py-2.5 text-sm rounded-lg';
      case 'large':
        return 'px-7 py-3.5 text-lg rounded-2xl';
      default:
        return 'px-6 py-3 text-base rounded-xl';
    }
  };

  const getColorClassName = (): string => {
    switch (color) {
      case 'blue':
        return 'bg-blue-500 text-white hover:bg-blue-600';
      case 'green':
        return 'bg-mainGreen text-white hover:bg-green-600';
      case 'red':
        return 'bg-red-500 text-white hover:bg-red-600';
      case 'gray':
        return 'bg-gray-400 text-white hover:bg-gray-500';
      default:
        return 'bg-mainGreen text-white hover:bg-green-600';
    }
  };
  const buttonClassName = cn(
    'inline-block',
    getSizeClassName(),
    getColorClassName(),
    'font-bold',
    'focus:outline-none',
    className,
  );
  return (
    <button className={buttonClassName} onClick={onClick}>
      {children}
    </button>
  );
};

export default Button;

import { Locale } from 'types/types';
import { create } from 'zustand';
import { createJSONStorage, devtools, persist } from 'zustand/middleware';

interface localeState {
  locale: Locale;
  setLocale: (locale: Locale) => void;
  isEnglish: boolean;
  isKorean: boolean;
  isVietnamese: boolean;
}

const useLocaleStore = create<localeState>()(
  devtools(
    persist(
      (set) => ({
        locale: 'KR',
        isEnglish: false,
        isKorean: true,
        isVietnamese: false,
        setLocale: (locale) =>
          set(() => ({
            locale: locale,
            isEnglish: locale === 'ENG',
            isKorean: locale === 'KR',
            isVietnamese: locale === 'VNM',
          })),
      }),
      {
        name: 'locale-store',
        storage: createJSONStorage(() => localStorage),
      },
    ),
  ),
);

export default useLocaleStore;

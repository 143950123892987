import Button from './common/buttons/Button';
import useTranslate from 'hooks/useTranslate';
import useLogin from 'hooks/useLogin';
import IcLogo from './icon/IcLogo';

export default function NoneActiveFarm() {
  const t = useTranslate();
  const { handleLogout } = useLogin();

  return (
    <div className="flex items-center justify-center w-full h-[90vh]">
      <div className="absolute flex flex-col items-center gap-3 -translate-x-1/2 -translate-y-1/2 left-1/2 top-1/2 lg:gap-6">
        <div className="relative left-2">
          <IcLogo width={90} height={90} />
        </div>
        <p className="text-2xl font-bold text-center text-gray-400 lg:text-4xl break-keep">
          {t('no_active_farm_message')}
        </p>
        <div className={`relative`}>
          <Button
            onClick={() => {
              handleLogout();
              window.location.reload();
            }}
          >
            {t('로그아웃')}
          </Button>
        </div>
      </div>
    </div>
  );
}

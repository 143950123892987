import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { requestCellLatest } from 'api/cell';
import { insertTwinstarFarm } from 'api/master/insertTwinstarFarm';
import { Access, CellType } from 'types/types';
import { useAllFarmListQuery } from './allFarmListQuery';

export const MASTER_FARM_QUERY_KEY = 'twinstarFarm';

type Params = {
  targetFarmNumber: number;
};
export const useTwinstarFarmQuery = ({ targetFarmNumber }: Params) => {
  //targetAccess: 컨트롤러를 추가할 농장의 서버번호
  const { data: masterFarms } = useAllFarmListQuery();
  const targetAccess = masterFarms?.find(
    (farm) => farm.id === targetFarmNumber,
  )?.access;

  return useQuery({
    queryKey: [MASTER_FARM_QUERY_KEY, targetFarmNumber],
    queryFn: async () => {
      if (targetAccess === undefined) return null;
      const cells = await requestCellLatest({
        payload: { farmNumber: targetFarmNumber },
        access: targetAccess,
      });
      let lineCount = 0;
      let blockCount = 0;
      let floor = 0;
      let cellType: CellType = 1;
      cells.map((cell) => {
        if (cell.line_number > lineCount) {
          lineCount = cell.line_number;
        }
        if (cell.block_number > blockCount) {
          blockCount = cell.block_number;
        }
        if (cell.floor_number > floor) {
          floor = cell.floor_number;
        }
        cellType = cell.cell_type;
      });

      return { lineCount, blockCount, floor, cellType };
    },
  });
};

// 트윈스타 농장 추가 쿼리 훅
export const useInsertTwinstarFarmMutation = ({ targetFarmNumber }: Params) => {
  const queryClient = useQueryClient();

  //targetAccess: 컨트롤러를 추가할 농장의 서버번호
  const { data: masterFarms } = useAllFarmListQuery();
  const targetAccess = masterFarms?.find(
    (farm) => farm.id === targetFarmNumber,
  )?.access;
  return useMutation({
    mutationFn: async (
      payload: Omit<
        Parameters<typeof insertTwinstarFarm>[0],
        'farmNumber' | 'access'
      >,
    ) => {
      if (targetAccess === undefined)
        throw new Error('no selectedFarm or targetAccess');
      await insertTwinstarFarm({
        farmNumber: targetFarmNumber,
        ...payload,
        access: targetAccess,
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries(['twinstarFarm', targetFarmNumber]);
    },
  });
};

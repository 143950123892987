import customAxios from 'api/customAxios';
import { masterAuthUrl } from '.';
import { DefaultResponse } from 'api';
import { Access, Auth, Language } from 'types/types';

type Payload = {
  targetUserEmail: string; //수정할 타겟 사용자의 현재 이메일 (필수)
  targetCurrentFarmNumber: number; //수정할 타겟 사용자가 보유한 현재 농장 번호(필수)
  updatedUserName?: string; // 변경할 사용자명
  updatedAuth?: Auth; // 변경할 권한
  updatedLanguage?: Language; // 변경할 언어
  updatedAccess?: Access; // 변경하는 서버
  updatedFarmNumber?: number; // 변경할 사용자가 보유할 농장 번호
};

export const updateUser = async (payload: Payload) => {
  const requestBody = new FormData();
  requestBody.append('email', payload.targetUserEmail);
  requestBody.append('selected_id', payload.targetCurrentFarmNumber.toString());

  payload.updatedUserName &&
    requestBody.append('user', payload.updatedUserName);
  payload.updatedAuth !== undefined &&
    requestBody.append('auth', `${payload.updatedAuth}`);
  payload.updatedLanguage !== undefined &&
    requestBody.append('language', `${payload.updatedLanguage}`);
  payload.updatedAccess !== undefined &&
    requestBody.append('access', `${payload.updatedAccess}`);
  payload.updatedFarmNumber !== undefined &&
    requestBody.append('farm_number', payload.updatedFarmNumber.toString());
  try {
    //Access는 0번 서버로 고정
    const response = await customAxios.post<DefaultResponse>(
      `${masterAuthUrl(0)}/updateUser.php`,
      requestBody,
    );
    if (response.data.isSuccess === false) {
      throw new Error(response.data.message);
    }
    return response.data;
  } catch (error) {
    console.error('Request Master Auth Update User error', error);
    throw error;
  }
};

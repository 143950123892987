import clsx from 'clsx';
import { useAllUserListQuery } from 'hooks/reactQuery/master/usersQuery';
import useUserListPageStore from 'store/master/userListPageStore';

export default function UserPagination() {
  const { currentPage, setCurrentPage, userPerPage } = useUserListPageStore();
  const { data: users } = useAllUserListQuery();

  if (!users) return <div></div>;
  const maxPage = Math.ceil(users.length / userPerPage);
  const pageNumbers = Array.from({ length: maxPage }, (_, i) => i + 1);

  return (
    <div className="flex justify-center gap-x-2">
      {pageNumbers.map((number) => (
        <button
          key={number}
          className={clsx(
            'select-none text-sm text-subText rounded-full flexCenter w-[23px] h-[23px] text-center',
            {
              'bg-mainGreen text-white font-bold': number === currentPage,
            },
          )}
          onClick={() => setCurrentPage(number)}
        >
          {number}
        </button>
      ))}
    </div>
  );
}
